interface Line {
  code: string;
  description: string;
  invnr_part: string;
  invoice_nr: string;
  line_nr: number;
  payment_amount: number;
  price: number;
}

interface Invoice {
  invoice_nr: string;
  lines?: Line[];
  med_amount: string;
  med_outstanding: string;
  pat_amount: string;
  pat_outstanding: string;
  patient_dbid: number;
  payment_amount: string;
  service_center: string;
  total: string;
  treating_doc: string;
  treatment_date: string;
}

export interface ThemeData {
  diary_logo?: string;
  setup_logo?: string;
  diary_uuid: string;
  theme_colours?: {
    primary?: string;
    accent?: string;
  };
}

export interface PaymentData {
  paid: boolean;
  accountNumber: string;
  hash: string;
  client_code: string;
  invoices: Invoice[];
  paymentAmount: number;
  paymentUrl: string;
  ozowPaymentUrl?: string;
  stripePaymentUrl?: string;
  peachpayPaymentUrl?: string;
  fnbPaymentUrl?: string;
  invoiceDate: string;
  paymentReference?: string;
  treatingDoctor?: string;
  workflow_type?: string;
  theme_data?: ThemeData;
}

export class Payment {
  paid: boolean;
  accountNumber: string;
  hash: string;
  invoices: Invoice[];
  paymentAmount: number;
  paymentUrl: string;
  ozowPaymentUrl: string;
  stripePaymentUrl: string;
  peachpayPaymentUrl: string;
  fnbPaymentUrl: string;
  invoiceDate: string;
  paymentReference?: string;
  treatingDoctor?: string;
  type: string;
  clientCode?: string;
  themeData?: ThemeData;

  constructor(data: PaymentData) {
    this.paid = data.paid;
    this.accountNumber = data.accountNumber;
    this.hash = data.hash;
    this.invoices = data.invoices;
    this.paymentAmount = data.paymentAmount;
    this.paymentUrl = data.paymentUrl;
    this.ozowPaymentUrl = data.ozowPaymentUrl;
    this.stripePaymentUrl = data.stripePaymentUrl;
    this.peachpayPaymentUrl = data.peachpayPaymentUrl;
    this.fnbPaymentUrl = data.fnbPaymentUrl;
    this.invoiceDate = data.invoiceDate;
    this.paymentReference = data.paymentReference;
    this.treatingDoctor = data.treatingDoctor;
    this.type = data.paid ? 'paid' : 'unpaid';
    this.clientCode = data.client_code;
    this.themeData = data.theme_data;
  }
}
