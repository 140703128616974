<div class="breadcrumb-card">
  <button mat-icon-button type="button" class="fit-icon" (click)="onBackClick()" [routerLink]="defaultBackFunction ? '../' : []">
    <mat-icon svgIcon="arrow-left"></mat-icon>
  </button>
  <ng-container *ngFor="let crumb of crumbs; let i = index; let last = last">
    <span
      (click)="!defaultBackFunction && selectedIndex !== i ? onSelectClick(i) : []"
      [routerLink]="defaultBackFunction && !last ? '../'.repeat(crumbs.length - i - 1) : []"
      class="link"
      [class.selected]="selectedIndex !== null ? selectedIndex === i : last"
      [class.last]="last"
    >
      {{ crumb }}
    </span>
    <span *ngIf="!last">&nbsp;&gt;&nbsp;</span>
  </ng-container>
  <ng-content></ng-content>
</div>
