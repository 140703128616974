<h1 mat-dialog-title>
  <mat-icon [svgIcon]="icon"></mat-icon>
  <span class="title">{{ title }}</span>
</h1>
<div mat-dialog-content class="question-container">
  <form [formGroup]="form" (submit)="submit()">
    <div>
      Kindly take a moment to send us your feedback of your
      <span class="mygc-text">myGC</span>
      experience.
    </div>
    <div class="note">
      Please note: we cannot make a booking for you.
      <br />
      Please contact your medical practitioner directly if you are struggling to make a booking on myGC.
    </div>
    <div *ngFor="let question of questions" class="question-container">
      <div *ngIf="question.type === 'rating'" class="ratings">
        <label *ngFor="let option of question.options" class="rating">
          <input formControlName="rating" [value]="option.value" type="radio" />
          <div class="radio-replacement">
            <div class="icon">
              <mat-icon [svgIcon]="option.icon"></mat-icon>
            </div>
            <div class="label">{{ option.label }}</div>
          </div>
        </label>
      </div>
      <mat-form-field *ngIf="isQuestionTypeMatFormField(question.type)">
        <mat-label>
          {{ question.label }}
          <span *ngIf="question.required">*</span>
        </mat-label>
        <input matNativeControl formControlName="comment" type="text" />
      </mat-form-field>
    </div>
    <div *ngIf="!isAuth">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" autocomplete="given-name" name="name" gcFormField />
      </mat-form-field>
      <mat-form-field>
        <mat-label>ID Number (optional)</mat-label>
        <input matInput formControlName="id_number" name="id_number" gcFormField />
      </mat-form-field>
    </div>
    May we contact you to hear more about your feedback above?
    <div class="contact">
      <label class="radio">
        <input class="left" formControlName="contact" [value]="true" type="radio" />
        <div class="radio-replacement left">
          <div class="label">
            <mat-icon class="check">check</mat-icon>
            Yes
          </div>
        </div>
      </label>
      <label class="radio">
        <input class="right" formControlName="contact" [value]="false" type="radio" />
        <div class="radio-replacement right">
          <div class="label">
            <mat-icon class="check">check</mat-icon>
            No
          </div>
        </div>
      </label>
    </div>
    <div *ngIf="!hideContactMethod">
      How would you prefer to be contacted?
      <div class="contact">
        <label class="radio">
          <input class="left" formControlName="contactMethod" value="email" type="radio" />
          <div class="radio-replacement left">
            <div class="label">
              <mat-icon class="check">check</mat-icon>
              Email
            </div>
          </div>
        </label>
        <label class="radio">
          <input class="right" formControlName="contactMethod" value="phone" type="radio" />
          <div class="radio-replacement right">
            <div class="label">
              <mat-icon class="check">check</mat-icon>
              Phone
            </div>
          </div>
        </label>
      </div>
      <div *ngIf="!isAuth">
        <mat-form-field *ngIf="isEmailSelected; else phoneSelected">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" name="email" type="email" gcFormField />
        </mat-form-field>
        <ng-template #phoneSelected>
          <mat-form-field>
            <mat-label>Phone</mat-label>
            <input matInput formControlName="cellnr" name="cellnr" type="tel" gcFormField />
          </mat-form-field>
        </ng-template>
      </div>
    </div>
  </form>
  Would you like to become a beta tester?
  <gc-beta-user [profile]="profile$ | async"></gc-beta-user>
</div>
<div mat-dialog-actions>
  <div class="footer-buttons">
    <button mat-button mat-dialog-close class="cancel" tabindex="-1">CANCEL</button>
    <button mat-button [mat-dialog-close]="form.value" type="submit" [disabled]="form.invalid">SEND</button>
  </div>
</div>
