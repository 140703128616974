import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

import { AboutDialogComponent } from '@dialogs/about-dialog/about-dialog.component';

import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { FeedbackService } from '@services/feedback.service';
import { NotificationService } from '@services/notification.service';

import { ActivationStart, Router } from '@angular/router';
import { feedbackTypes } from '@modules/shared/dialogs/feedback-dialog/feedback-dialog.component';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { DisplayService } from '@services/display.service';

interface ToolbarLink {
  label: string;
  icon: string;
  path?: string;
  dialog?: string;
  amountBadge?: Observable<number>;
}

@Component({
  selector: 'gc-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [style({ transform: 'translateX(-100%)' }), animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
})
export class SidebarNavComponent implements OnInit, OnDestroy {
  desktopImgSrc = 'assets/images/myGCLogo_vertical.svg';
  mobileImgSrc = 'assets/images/myGCLogo_default.svg';
  loggedIn$: Observable<boolean>;
  toolbarLinks$: Observable<ToolbarLink[]>;
  @HostBinding('class.visible') visible = true;
  unsubscribe$ = new Subject<void>();
  alwaysVisible = false;
  @Input() loggedIn = true;

  bottomLinks = [
    { label: 'Feedback', icon: 'send', dialog: 'feedback' },
    { label: 'About <span class="mygc-text">myGC</span>', icon: 'information', dialog: 'about' },
  ];

  constructor(
    private readonly alert: AlertService,
    private readonly auth: AuthService,
    private readonly display: DisplayService,
    private readonly router: Router,
    private readonly notification: NotificationService,
    private readonly feedback: FeedbackService,
  ) {}

  ngOnInit() {
    // create an observable for whether or not the user is authenticated
    this.loggedIn$ = this.auth.authenticated$;
    // create consts for links in the sidebar
    const loggedInLinks = [
      { label: 'Dashboard', icon: 'home', path: '/dashboard' },
      { label: 'Notifications', icon: 'bell', path: '/notifications', amountBadge: this.notification.amountOfUnreadNotifications$ },
      { label: 'Health', icon: 'heart', path: '/health' },
      { label: 'Bookings', icon: 'stethoscope', path: '/bookings' },
      { label: 'Secure Message', icon: 'forum-outline', path: '/secure-message' },
      { label: 'Payments', icon: 'credit-card-outline', path: '/payments' },
      { label: 'Account', icon: 'wrench', path: '/setup' },
    ];
    const loggedOutLinks = [
      { label: 'Log in', icon: 'login-variant', path: '/login' },
      { label: 'Sign up', icon: 'account-plus', path: '/register' },
      { label: 'Contact us', icon: 'phone', dialog: 'feedback' },
      { label: 'Back to Home Page', icon: 'arrow-left', path: '/landing' },
    ];

    this.toolbarLinks$ = this.loggedIn$.pipe(map((loggedIn) => (loggedIn ? loggedInLinks : loggedOutLinks)));

    this.display.isMobile$.pipe(takeUntil(this.unsubscribe$)).subscribe((isMobile) => {
      if (isMobile) {
        this.visible = this.alwaysVisible = false;
        return;
      }
      this.visible = this.alwaysVisible = !isMobile && !this.loggedIn;
    });

    this.router.events
      .pipe(
        filter((evt) => evt instanceof ActivationStart),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.visible = false;
      });
  }

  toggle() {
    this.visible = !this.visible;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  linkActive(link: ToolbarLink) {
    return this.router.url.startsWith(link.path);
  }

  linkClicked(link: ToolbarLink) {
    if (this.loggedIn) {
      this.visible = false;
    }
    if (link.path) {
      this.router.navigate([link.path]);
    } else {
      this.showDialog(link.dialog);
    }
  }

  logout() {
    this.alert
      .messageDialog$('Confirm log out', 'Are you sure you want to log out?', 'error', true)
      .pipe(
        filter((data) => !!data),
        tap(() => {
          this.auth.logout().subscribe(() => {
            this.router.navigate(['/landing']);
          });
        }),
      )
      .subscribe();
  }

  showDialog(dialog: string) {
    if (dialog === 'about') {
      this.alert.openDialog$(AboutDialogComponent, { panelClass: 'gc-about-dialog' });
    } else if (dialog === 'feedback') {
      this.feedback.feedbackDialog(feedbackTypes.general).subscribe();
    }
  }
}
