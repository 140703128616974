<form class="container" [formGroup]="passwordForm" (submit)="submit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Password Reset</mat-card-title>
      <mat-card-subtitle>
        Please enter and confirm your new
        <b><i>myGC</i></b>
        password
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="new-password-input">
      <mat-form-field class="multiline-error">
        <mat-label>Password</mat-label>
        <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" autocomplete="new-password" />
        <button
          mat-icon-button
          matSuffix
          type="button"
          *ngIf="passwordForm.get('password').value"
          (click)="showPassword = !showPassword"
          tabindex="-1"
        >
          <mat-icon [svgIcon]="showPassword ? 'eye-off' : 'eye'"></mat-icon>
        </button>
        <mat-error>
          {{ passwordErrors() }}
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button color="primary" type="submit" [disabled]="passwordForm.invalid">Submit</button>
    </mat-card-actions>
  </mat-card>
</form>
