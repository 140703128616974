import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  public updatesAvailable$: Observable<boolean>;

  constructor(private readonly update: SwUpdate, private readonly appRef: ApplicationRef) {
    // millisecond -> minute -> 30 minutes
    const every30Minutes$ = interval(1000 * 60 * 30);
    const appIsStable$ = this.appRef.isStable.pipe(first((stable) => stable === true));
    // Check for updates every 30 minutes once the app is stable if service workers are enabled
    if (this.update.isEnabled) {
      concat(appIsStable$, every30Minutes$)
        .pipe(tap(async () => this.update.checkForUpdate()))
        .subscribe();
    }
    this.updatesAvailable$ = this.update.isEnabled
      ? this.update.versionUpdates.pipe(map((val) => val.type === 'VERSION_READY'))
      : new Observable<boolean>();
  }

  public activateUpdate() {
    this.update.activateUpdate().then(() => document.location.reload());
  }
}
