import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { DateTime } from 'luxon';
import { EmployerDetails } from './employer.model';

interface DemographicRequestPatientAddress {
  line1: string;
  line2?: string;
  line3?: string;
  code: string;
}

export interface DemographicRequestConfig {
  name: (string | ((control: AbstractControl) => ValidationErrors))[];
  surname: (string | ((control: AbstractControl) => ValidationErrors))[];
  initials?: (string | ((control: AbstractControl) => ValidationErrors))[];
  title?: (string | ((control: AbstractControl) => ValidationErrors))[];
  idnumber?: (string | ((control: AbstractControl) => ValidationErrors))[];
  idtype?: (string | ((control: AbstractControl) => ValidationErrors))[];
  dob?: (string | ((control: AbstractControl) => ValidationErrors))[];
  email?: (string | ((control: AbstractControl) => ValidationErrors))[];
  cellnr?: (string | ((control: AbstractControl) => ValidationErrors))[];
  homenr?: (string | ((control: AbstractControl) => ValidationErrors))[];
  worknr?: (string | ((control: AbstractControl) => ValidationErrors))[];
  gender?: (string | ((control: AbstractControl) => ValidationErrors))[];
  dbid?: (string | ((control: AbstractControl) => ValidationErrors))[];
  nok_name?: (string | ((control: AbstractControl) => ValidationErrors))[];
  nok_number?: (string | ((control: AbstractControl) => ValidationErrors))[];
  physical_address?: FormGroup;
  postal_address?: FormGroup;
  medical_aid_front?: (string | ((control: AbstractControl) => ValidationErrors))[];
  medical_aid_back?: (string | ((control: AbstractControl) => ValidationErrors))[];
  dependant_code?: (string | ((control: AbstractControl) => ValidationErrors))[];
  dependant_type?: (string | ((control: AbstractControl) => ValidationErrors))[];
  identification_front?: (string | ((control: AbstractControl) => ValidationErrors))[];
  identification_back?: (string | ((control: AbstractControl) => ValidationErrors))[];
}

export interface MedicalAidConfig {
  private: (boolean | ((control: AbstractControl) => ValidationErrors))[];
  scheme: (string | ((control: AbstractControl) => ValidationErrors))[];
  option: (string | ((control: AbstractControl) => ValidationErrors))[];
  number: (string | ((control: AbstractControl) => ValidationErrors))[];
  medical_aid_front?: (string | ((control: AbstractControl) => ValidationErrors))[];
  medical_aid_back?: (string | ((control: AbstractControl) => ValidationErrors))[];
}

export interface AddressConfig {
  line1: (string | ((control: AbstractControl) => ValidationErrors))[];
  line2: (string | ((control: AbstractControl) => ValidationErrors))[];
  line3: (string | ((control: AbstractControl) => ValidationErrors))[];
  code: (string | ((control: AbstractControl) => ValidationErrors))[];
}

export interface DemographicRequestPatient {
  name: string;
  surname: string;
  initials?: string;
  title?: string;
  idnumber?: string;
  idtype?: string;
  dob?: string;
  email?: string;
  cellnr?: string;
  homenr?: string;
  worknr?: string;
  gender?: string;
  dbid?: number;
  medical_aid?: {
    medical_aid_number?: string;
    medical_aid_scheme_name?: string;
    medical_aid_scheme_code?: string;
    medical_aid_option_name?: string;
    medical_aid_option_code?: string;
    dependant_type?: string;
    dependant_code?: string;
  };
  employer?: EmployerDetails;
  nok_name?: string;
  nok_number?: string;
  physical_address?: DemographicRequestPatientAddress;
  postal_address?: DemographicRequestPatientAddress;
  medical_aid_front?: string;
  medical_aid_back?: string;
  identification_front?: string;
  identification_back?: string;
}
export interface DemographicRequestData {
  uuid?: string;
  booking_uuid?: string;
  operation_uuid?: string;
  user_id?: number;
  include_history: boolean;
  patient_json: DemographicRequestPatient;
  main_member_json: DemographicRequestPatient;
  dependants_json: DemographicRequestPatient[];
  accepted: boolean;
  rejected: boolean;
  doctor_name: string;
  doctor_logo?: string;
  requested_timestamp: string;
  responded_timestamp?: string;
  selected_patient_id?: number;
  required_fields?: string[];
}

export class DemographicRequest {
  uuid: string;
  bookingUuid: string;
  operationUuid: string;
  userId: number;
  includeHistory: boolean;
  userDetails: DemographicRequestPatient;
  mainMember: DemographicRequestPatient;
  dependants: DemographicRequestPatient[];
  doctorName: string;
  doctorLogo: string;
  accepted: boolean;
  rejected: boolean;
  requestedOn: DateTime;
  respondedOn: DateTime;
  requiredFields: string[];

  constructor(data: DemographicRequestData) {
    this.uuid = data.uuid;
    this.bookingUuid = data.booking_uuid;
    this.operationUuid = data.operation_uuid;
    this.userId = data.user_id;
    this.includeHistory = data.include_history;
    this.userDetails = data.patient_json;
    this.mainMember = data.main_member_json;
    this.dependants = data.dependants_json;
    this.doctorName = data.doctor_name;
    this.accepted = data.accepted;
    this.rejected = data.rejected;
    this.requestedOn = DateTime.fromISO(data.requested_timestamp);
    this.respondedOn = data.responded_timestamp && DateTime.fromISO(data.responded_timestamp);
    this.doctorLogo = data.doctor_logo;
    this.requiredFields = data.required_fields;
  }

  get complete() {
    return this.accepted || this.rejected;
  }
}
