<div class="popup-container">
  <div class="filter-header">
    <h2>Filter Options</h2>
  </div>
  <div class="filter-list">
    <button class="filter-item" *ngFor="let option of types" [class.selected]="option.selected" (click)="onToggleType(option)">
      <div class="filter-item-content">
        <mat-icon [svgIcon]="option.icon"></mat-icon>
        <span>{{ option.title }}</span>
      </div>
      <mat-icon *ngIf="option.selected" class="check-icon">check</mat-icon>
    </button>
  </div>
  <div class="filter-actions">
    <button mat-stroked-button color="primary" (click)="onRemoveAll()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onApply()">Apply Filter</button>
  </div>
</div>
