import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingType, BookingTypeData } from '@models/booking-type.model';
import { Doctor, DoctorData } from '@models/doctor.model';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

interface Coordinates {
  latitude: number;
  longitude: number;
}

@Injectable({
  providedIn: 'root',
})
export class DoctorService {
  private _coords: Coordinates = { latitude: null, longitude: null };
  private readonly stopTimeslots$ = new Subject<void>();
  busyTimeslotDoctors: Doctor[] = [];

  constructor(private readonly http: HttpClient) {}

  setCoords(latitude: number, longitude: number) {
    this._coords = { latitude, longitude };
    localStorage.setItem('coords', JSON.stringify(this._coords));
  }

  getCoords() {
    if (!this._coords.latitude || !this._coords.longitude) {
      const localCoords = localStorage.getItem('coords');
      if (localCoords) {
        const coords: Coordinates = JSON.parse(localCoords);
        if (coords.latitude && coords.longitude) {
          this._coords = coords;
        }
      }
    }
    return this._coords.latitude && this._coords.longitude ? this._coords : null;
  }

  getDoctors({
    lat,
    lng,
    searchTerm,
    speciality,
    practice,
  }: { lat?: number; lng?: number; searchTerm?: string; speciality?: string; practice?: string } = {}) {
    if (lat && lng) {
      this.setCoords(lat, lng);
    }
    const params: { [k: string]: string } = JSON.parse(
      JSON.stringify({
        lat: lat && lat.toString(),
        lng: lng && lng.toString(),
        speciality,
        search_term: searchTerm,
        practice,
      }),
    );
    return this.http
      .get<DoctorData[]>('/api/doctors', {
        params,
      })
      .pipe(map((res) => res.map((doctor) => new Doctor(doctor))));
  }

  getDoctor$(identifier: string, timeslots?: boolean): Observable<Doctor> {
    const params: Record<string, unknown> = {
      get_timeslots: !!timeslots,
    };
    return this.http
      .get<DoctorData>(`/api/doctor/${identifier}`, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        headers: { 'http-loader-disable': 'disable' },
        params: params as unknown as HttpParams,
      })
      .pipe(map((res) => new Doctor(res)));
  }

  getDoctorByClientCode$(code: string): Observable<DoctorData> {
    return this.http.get<DoctorData>(`/api/doctor/client-code/${code}`).pipe(
      map(
        (res) => res,
        (error: Error) => {
          console.error(`Doctor not found ${error}`);
        },
      ),
    );
  }

  getSpecialities$(): Observable<string[]> {
    return this.http.get<string[]>('/api/doctors/specialities');
  }

  getSavedDoctors$(): Observable<Doctor[]> {
    return this.http
      .get<DoctorData[]>('/api/doctors', { params: { saved: 'true' } })
      .pipe(switchMap((res) => [res.map((doctorData) => new Doctor(doctorData))]));
  }

  updateBookingTypes$(doctor: Doctor): Observable<Doctor> {
    const identifier = doctor.uuid || doctor.code;
    this.busyTimeslotDoctors.push(doctor);
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .get<BookingTypeData[]>(`/api/doctor/${identifier}/bookingtypes`, { headers: { 'http-loader-disable': 'disable' } })
        .pipe(
          catchError(() => of(null)),
          map((bookingTypes: BookingTypeData[] | null) => {
            doctor.bookingTypes = bookingTypes ? bookingTypes.map((res) => new BookingType(res)) : null;
            return doctor;
          }),
        )
    );
  }

  updateTimeslots(doctors: Doctor[]) {
    if (doctors?.length) {
      merge(...doctors.filter((x) => !!x.uuid).map((d) => this.updateBookingTypes$(d)), 3)
        .pipe(takeUntil(this.stopTimeslots$))
        .subscribe((res: Doctor) => {
          this.busyTimeslotDoctors.splice(
            this.busyTimeslotDoctors.findIndex((x) => x.uuid === res.uuid),
            1,
          );
        });
    }
  }

  stopTimeslotsGet(): void {
    this.stopTimeslots$.next();
  }

  isLoadingTimeslots(doctor: Doctor): boolean {
    return !!this.busyTimeslotDoctors.find((x) => x.uuid === doctor.uuid);
  }

  saveDoctor$(data: { identifier: string; save: boolean }): Observable<boolean> {
    if (data.save) {
      return this.http.patch<{ saved: boolean }>(`/api/doctors/${data.identifier}/save`, {}).pipe(map((res) => res.saved));
    } else {
      return this.http.delete<{ saved: boolean }>(`/api/doctors/${data.identifier}/save`).pipe(map((res) => res.saved));
    }
  }
}
