<div class="payments" *ngIf="payment && payAllInvoices">
  <div class="options">
    <div class="title">How would you like to pay?</div>
    <mat-radio-group [formControl]="selectedPaymentOption" class="radio-option">
      <mat-radio-button class="option" *ngFor="let payment_option of paymentOptions" [value]="payment_option.value">
        <div class="option-text">
          <div>
            Pay {{ payment?.paymentAmount | currency : (currencyCode$ | async) : 'symbol-narrow' }} with
            <b>{{ payment_option.name }}</b>
          </div>
          <img src="{{ payment_option.logo }}" alt="{{ payment_option.name }}" />
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="continue-button">
    <button mat-flat-button (click)="location.back()" type="button">Back</button>
    <button mat-flat-button (click)="openPayment()" type="button" color="primary" [disabled]="!this.selectedPaymentOption.value">
      <mat-icon svgIcon="arrow-right"></mat-icon>
      Continue
    </button>
  </div>
  <div>
    <div class="view-payment">
      <gc-view-payment [payment]="selectedPayment" url="/payments"></gc-view-payment>
    </div>
  </div>
</div>
