import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[gcFormField]',
})
export class FormFieldDirective {
  @Input() gcFormFieldNoError = null;
  constructor(private readonly el: ElementRef<HTMLElement>) {}

  get formField() {
    return this.el.nativeElement.closest('mat-form-field');
  }
}
