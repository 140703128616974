<h1 mat-dialog-title>
  <mat-icon color="warn" svgIcon="alert-circle-outline"></mat-icon>
  <span>Are you sure?</span>
</h1>
<span mat-dialog-content>
  Deleting your myGC account is
  <strong>permanent</strong>
  and
  <strong>cannot be reversed.</strong>
</span>
<br />
<span mat-dialog-content class="error">Type your password below to confirm deletion of your myGC account:</span>
<br />
<form [formGroup]="passwordForm" (submit)="submit()">
  <mat-form-field class="password">
    <mat-label>Password</mat-label>
    <input matInput [type]="show ? 'text' : 'password'" formControlName="password" autocomplete="current-password" />
    <button mat-icon-button matSuffix type="button" (click)="show = !show" tabindex="-1">
      <mat-icon [svgIcon]="show ? 'eye-off' : 'eye'"></mat-icon>
    </button>
    <mat-error>
      <ng-container *ngIf="passwordForm.get('password').hasError('required')" ￼>Password is required</ng-container>
    </mat-error>
  </mat-form-field>
  <div class="action-container" mat-dialog-actions>
    <button mat-button mat-dialog-close class="cancel-button uppercase">Cancel</button>
    <button mat-button [mat-dialog-close]="true" class="error uppercase" type="submit" [disabled]="passwordForm.invalid">Delete</button>
  </div>
</form>
