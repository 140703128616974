import { Component, OnInit } from '@angular/core';
import { ConfigLocaleService, PageConfig } from '@services/configLocale.service';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'gc-terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrls: ['./terms-and-conditions-dialog.component.scss'],
})
export class TermsAndConditionsDialogComponent implements OnInit {
  termsAndConditions: PageConfig;
  constructor(private readonly configLocaleService: ConfigLocaleService) {}

  ngOnInit(): void {
    this.configLocaleService
      .getPageByKey$('terms_and_conditions')
      .pipe(catchError(() => of(null)))
      .subscribe((data) => {
        this.termsAndConditions = data;
      });
  }
}
