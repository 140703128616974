import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { PracticeService } from '@services/practice.service';

@Injectable({
  providedIn: 'root',
})
export class LegacyUrlResolverService {
  constructor(private readonly alert: AlertService, private readonly router: Router, private readonly practice: PracticeService) {}
  resolve(myRoute: ActivatedRouteSnapshot) {
    // Get relevant query params
    const practice = myRoute.queryParams?.practice;
    const diary = myRoute.queryParams?.diary;
    let hash;

    let urlType = myRoute.url.find((x) => x.path === 'kiosk') ? 'kiosk' : 'doctors';
    const isTinyUrl = myRoute.url[0].path === 't';

    if (isTinyUrl) {
      hash = myRoute.url[1].path;
      urlType = 'tinyurl';
      if (!hash) {
        this.notAValidURL();
        return;
      }
    } else if (['kiosk', 'doctors'].find((x) => x === urlType) && !(practice && diary)) {
      this.notAValidURL();
      return;
    }
    switch (urlType) {
      case 'doctors':
        this.practice.getLegacyDiaryUUID$(practice, diary).subscribe(
          (uuid) => {
            this.router.navigate(['external', 'diary', uuid]);
          },
          () => {
            this.notAValidURL();
          },
        );
        break;
      case 'kiosk':
        this.practice.getLegacyKioskUUID$(practice, diary).subscribe(
          (uuid) => {
            this.router.navigate(['kiosk', uuid]);
          },
          () => {
            this.notAValidURL();
          },
        );
        break;
      case 'tinyurl':
        this.practice.getLegacyTinyUrl(hash).subscribe(
          (url) => {
            this.router.navigate([url]);
          },
          () => {
            this.notAValidURL();
          },
        );
        break;
      default:
        break;
    }
  }
  private notAValidURL() {
    this.alert.snackbar('Error. Not a valid URL');
    this.router.navigate(['not-found']);
  }
}
