import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DetailsCompleteGuard {
  constructor(private readonly user: UserService, private readonly router: Router, private readonly auth: AuthService) {}
  canActivate() {
    const profile$ = this.user.getUserProfile().pipe(
      map((profile) => !!profile.cellnr && !!profile.termsAccepted),
      tap((res) => {
        if (!res) {
          this.router.navigate(['/complete-details']);
        }
      }),
    );
    return this.auth.authenticated$.pipe(switchMap((authenticated) => (authenticated ? profile$ : of(true))));
  }
  canActivateChild() {
    return this.canActivate();
  }
}
