import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClinicalDocument, ClinicalDocumentData } from '@models/clinical-document.model';
import { ConsentForm, ConsentFormData } from '@models/consent-form.model';
import { DemographicRequest, DemographicRequestData } from '@models/demographic-request.model';
import { PatientNote } from '@models/patient-note.model';
import { PatientAttachment, PatientAttachmentFileData } from '@models/patient-attachment.model';
import { Questionnaire, QuestionnaireData } from '@models/questionnaire.model';
import { ReferralLetter } from '@models/referral-letter.model';
import { ErrorResponse } from '@models/responses.model';
import { Script } from '@models/script.model';
import { SickNote } from '@models/sicknote.model';
import { DateTime } from 'luxon';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from '@services/alert.service';
import { PdfDocument, ClaimedPDFData } from '@models/pdf-event.model';

@Injectable({
  providedIn: 'root',
})
export class ClinicalService {
  clinicalDocumentTypes = [
    { name: 'Sick Note', key: 'sicknote', icon: 'file-document' },
    { name: 'Script', key: 'script', icon: 'pill' },
    { name: 'Referral Letter', key: 'referral-letter', icon: 'file-send' },
    { name: 'Note', key: 'note', icon: 'file-edit-outline' },
    { name: 'Attachment', key: 'attachment', icon: 'attachment' },
    { name: 'Document', key: 'documents', icon: 'file-document-outline' },
  ];

  constructor(private readonly http: HttpClient, private readonly alert: AlertService) {}

  private getBaseUrl(userId: number): string {
    return `/api/users/${userId}/clinical`;
  }

  private getDocuments$(
    endpoint: string,
    params: { [key: string]: any },
    constructor: typeof ClinicalDocument,
  ): Observable<ClinicalDocument[]> {
    params = JSON.parse(JSON.stringify(params));
    return this.http.get<ClinicalDocumentData[]>(`/api/clinical/${endpoint}`, { params }).pipe(
      map((docs) => docs.map((d) => new constructor(d))),
      catchError((err: unknown) => {
        this.alert.snackbar(`Error retrieving documents - ${(err as ErrorResponse).message}`);
        return EMPTY;
      }),
    );
  }

  getScripts$(userId?: number): Observable<ClinicalDocument[]> {
    return this.getDocuments$('scripts', { user_id: userId }, Script);
  }

  getSickNotes$(userId?: number): Observable<ClinicalDocument[]> {
    return this.getDocuments$('sicknotes', { user_id: userId }, SickNote);
  }

  getPatientNotes$(userId?: number): Observable<ClinicalDocument[]> {
    return this.getDocuments$('notes', { user_id: userId }, PatientNote);
  }

  getAttachments$(userId?: number): Observable<ClinicalDocument[]> {
    return this.getDocuments$('attachments', { user_id: userId }, PatientAttachment);
  }

  getReferralLetters$(userId?: number): Observable<ClinicalDocument[]> {
    return this.getDocuments$('referral-letters', { user_id: userId }, ReferralLetter);
  }

  getPdfEvents$(userId?: number): Observable<ClinicalDocument[]> {
    return this.getDocuments$('pdf_event', { user_id: userId }, PdfDocument);
  }

  postClaimedPDF(clientCode: string, claimedPDFData: ClaimedPDFData) {
    return this.http.post('/api/clinical/pdf_event/auto-claim', { client_code: clientCode, ...claimedPDFData });
  }

  getClinicalDocuments(userId?: number) {
    const functions = [
      this.getScripts$,
      this.getSickNotes$,
      this.getPatientNotes$,
      this.getReferralLetters$,
      this.getAttachments$,
      this.getPdfEvents$,
    ].map((f) => f.bind(this)(userId));
    return combineLatest(functions).pipe(
      map((documents: ClinicalDocument[][]) => [].concat(...documents)),
      map((documents) => documents.sort((a, b) => (a.date.diff(b.date).milliseconds <= 0 ? 1 : -1))),
    );
  }

  downloadClinicalDocumentsZip(userId?: number) {
    const params = JSON.parse(JSON.stringify({ user_id: userId }));
    return this.http
      .get('/api/clinical/download', { params, responseType: 'blob' })
      .pipe(
        map(
          (blob) =>
            new File([blob], `clinical-documents-${DateTime.now().toFormat('yyyy-MM-dd_HH-mm-ss')}.zip`, { type: 'application/zip' }),
        ),
      );
  }

  createNote(title: string, body: string, userId: number, date?: DateTime) {
    const data = { title, body, user_id: userId, date: date?.toISODate() };
    return this.http.post<ClinicalDocumentData>('/api/clinical/notes', data).pipe(map((res) => new PatientNote(res)));
  }

  uploadAttachments$(title: string, filedata: PatientAttachmentFileData, userId: number): Observable<PatientAttachment> {
    const data = { user_id: userId, title, filedata, date: DateTime.now().toISODate() };
    return this.http.post<ClinicalDocumentData>('/api/clinical/attachments', data).pipe(
      map((res) => new PatientAttachment(res)),
      catchError((err: unknown) => {
        this.alert.snackbar(`Error uploading ${title} - ${(err as ErrorResponse).message}`);
        return EMPTY;
      }),
    );
  }

  getQuestionnaires(userId: number) {
    return this.http
      .get<QuestionnaireData[]>(`${this.getBaseUrl(userId)}/questionnaires`)
      .pipe(map((questionnaires) => questionnaires.map((q) => new Questionnaire(q))));
  }

  getQuestionnaire(userId: number, uuid: string) {
    return this.http
      .get<QuestionnaireData>(`${this.getBaseUrl(userId)}/questionnaires/${uuid}`)
      .pipe(map((questionnaire) => new Questionnaire(questionnaire)));
  }

  submitQuestionnaire(userId: number, uuid: string, data: { id: number; value: any }[]) {
    return this.http.patch(`${this.getBaseUrl(userId)}/questionnaires/${uuid}`, data);
  }

  submitQuestionnaireKiosk(uuid: string, data: { id: number; value: any }[]) {
    return this.http.patch(`/api/kiosk/clinical/questionnaires/${uuid}`, data);
  }

  getConsentForms(userId: number) {
    return this.http
      .get<ConsentFormData[]>(`${this.getBaseUrl(userId)}/consentforms`)
      .pipe(map((forms) => forms.map((f) => new ConsentForm(f))));
  }

  getConsentForm(userId: number, uuid: string) {
    return this.http.get<ConsentFormData>(`${this.getBaseUrl(userId)}/consentforms/${uuid}`).pipe(map((form) => new ConsentForm(form)));
  }

  submitConsentForm(userId: number, uuid: string, form: Partial<ConsentFormData>) {
    return this.http
      .patch<ConsentFormData>(`${this.getBaseUrl(userId)}/consentforms/${uuid}`, form)
      .pipe(map((formData) => new ConsentForm(formData)));
  }

  submitConsentFormKiosk(uuid: string, form: Partial<ConsentFormData>) {
    return this.http
      .patch<ConsentFormData>(`/api/kiosk/clinical/consentforms/${uuid}`, form)
      .pipe(map((formData) => new ConsentForm(formData)));
  }

  getDemographicRequests(userId: number) {
    return this.http
      .get<DemographicRequestData[]>(`${this.getBaseUrl(userId)}/demographic-requests`)
      .pipe(map((requestsData) => requestsData.map((data) => new DemographicRequest(data))));
  }

  getDemographicRequest(userId: number, uuid: string) {
    return this.http
      .get<DemographicRequestData>(`${this.getBaseUrl(userId)}/demographic-requests/${uuid}`)
      .pipe(map((requestData) => new DemographicRequest(requestData)));
  }

  getUnauthenticatedDemographicRequest(uuid: string) {
    return this.http
      .get<DemographicRequestData>(`/api/demographic-requests/${uuid}`)
      .pipe(map((requestData) => new DemographicRequest(requestData)));
  }

  rejectUnauthenticatedDemographicRequest(uuid: string) {
    return this.http.patch(`/api/demographic-requests/${uuid}`, { rejected: true });
  }

  submitDemographicRequest(userId: number, uuid: string, request: Partial<DemographicRequestData>) {
    return this.http
      .patch<DemographicRequestData>(`${this.getBaseUrl(userId)}/demographic-requests/${uuid}`, request)
      .pipe(map((requestData) => new DemographicRequest(requestData)));
  }

  submitDemographicRequestKiosk(uuid: string, request: Partial<DemographicRequestData>) {
    return this.http
      .patch<DemographicRequestData>(`/api/kiosk/clinical/demographic-requests/${uuid}`, request)
      .pipe(map((requestData) => new DemographicRequest(requestData)));
  }

  getUnauthenticatedConsentForm(uuid: string, { authType, authValue }: { authType: 'idnumber' | 'surname'; authValue: string }) {
    const params = { authType, authValue };
    return this.http
      .get<ConsentFormData>(`/api/consent-forms/${uuid}`, { params })
      .pipe(map((consentFormData) => new ConsentForm(consentFormData)));
  }

  getUnauthenticatedQuestionnaire(uuid: string, { authType, authValue }: { authType: 'idnumber' | 'surname'; authValue: string }) {
    const params = { authType, authValue };
    return this.http
      .get<QuestionnaireData>(`/api/questionnaires/${uuid}`, { params })
      .pipe(map((questionnaireData) => new Questionnaire(questionnaireData)));
  }

  revokeConsentForm(uuid: string, userId: number, reason: string) {
    const data = { revoked_reason: reason };
    return this.http
      .put<ConsentFormData>(`${this.getBaseUrl(userId)}/consentforms/${uuid}/revoke`, data)
      .pipe(map((consentFormData) => new ConsentForm(consentFormData)));
  }
}
