<div class="body" *ngIf="!selectedPayment" [@slideOutIn]>
  <!-- Filter for mobile -->
  <div
    class="filter filter-mobile"
    [class.search-mode]="searchMode"
    *ngIf="display.isMobile$ | async"
    matSort
    matSortDisableClear="true"
    matSortActive="invoiceDate"
    matSortDirection="desc"
  >
    <div class="search">
      <mat-icon svgIcon="magnify" class="magnifier" (click)="searchMode = true"></mat-icon>
      <input gcAutofocus type="text" name="search" placeholder="Search payments..." [formControl]="searchControl" />
      <mat-icon svgIcon="close" class="close-search" (click)="searchMode = false"></mat-icon>
    </div>
    <gc-type-filter minimal="true" [data]="_payments" (filterCallback)="onFilter($event)" [types]="typeFilters"></gc-type-filter>
    <p mat-sort-header="invoiceDate" mat-sort-start="desc">Date</p>
  </div>
  <!-- Filter for desktop -->
  <div class="hide-on-mobile filter">
    <div class="filter-header">
      <gc-search-input [formControl]="searchControl" placeholder="Search payments..."></gc-search-input>
      <h2 class="heading-1">Filter</h2>
    </div>
    <gc-type-filter #typeFilter [data]="_payments" (filterCallback)="onFilter($event)" [types]="typeFilters"></gc-type-filter>
  </div>
  <div class="payments-container">
    <div class="payments">
      <ng-container
        *ngIf="(display.isMobile$ | async) === false"
        matSort
        matSortDisableClear="true"
        matSortActive="invoiceDate"
        matSortDirection="desc"
      >
        <p mat-sort-header="invoiceDate" mat-sort-start="desc" class="hide-on-mobile">Date</p>
      </ng-container>
      <mat-accordion class="payments-accordion" hideToggle="true" *ngFor="let payment of payments$ | async; let i = index">
        <mat-expansion-panel
          [expanded]="step === i"
          (opened)="setStep(i)"
          [disabled]="(display.isMobile$ | async) === false"
          class="payments-expansion-panel"
        >
          <mat-expansion-panel-header class="payments-expansion-header">
            <span class="payments-expansion-row text-style">
              <mat-icon [color]="payment.paid ? 'accent' : 'warn'">
                {{ payment.paid ? 'check_circle' : 'error_outline' }}
              </mat-icon>
              <span>
                <span class="date">{{ payment.invoiceDate | date : 'longDate' }}, {{ payment.invoiceDate | date : 'shortTime' }}</span>
                <br />
                <span class="description" [class.error]="!payment.paid">
                  {{ payment.paymentAmount | currency : (currencyCode$ | async) : 'symbol-narrow' }} - {{ payment.treatingDoctor }}
                </span>
              </span>
              <button
                mat-flat-button
                (click)="selectPayment(payment)"
                [color]="payment.paid ? 'accent' : 'primary'"
                type="button"
                class="hide-on-mobile"
              >
                {{ payment.paid ? 'View Invoice' : 'Pay Now' }}
              </button>
            </span>
          </mat-expansion-panel-header>
          <mat-panel-description class="invoice-details">
            <span class="extra">
              <b>Account Number:</b>
              {{ payment.accountNumber }}
            </span>
            <span class="extra">
              <b>Invoice Date:</b>
              {{ payment.invoiceDate | date : 'longDate' }}, {{ payment.invoiceDate | date : 'shortTime' }}
            </span>
            <span class="extra">
              <b>Doctor:</b>
              {{ payment.treatingDoctor }}
            </span>
            <span class="extra" *ngIf="payment.invoices">
              <b>Number of Invoices:</b>
              {{ payment.invoices.length }}
            </span>
          </mat-panel-description>
          <mat-action-row class="actions">
            <button
              mat-flat-button
              (click)="selectPayment(payment)"
              [color]="payment.paid ? 'accent' : 'primary'"
              type="button"
              class="pay-now-mobile"
            >
              {{ payment.paid ? 'View Invoice' : 'Pay Now' }}
            </button>
            <button mat-flat-button class="hide-on-mobile nav-buttons" (click)="prevStep()">Previous</button>
            <button mat-flat-button class="hide-on-mobile nav-buttons" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <mat-paginator class="bottom" [pageSizeOptions]="[10, 20, 100]"></mat-paginator>
  </div>
</div>
<div>
  <gc-breadcrumb-bar
    class="header"
    *ngIf="selectedPayment"
    [@slideInOut]
    [crumbs]="['Payments', 'Make Payment']"
    [defaultBackFunction]="false"
    (backEvent)="backPayment()"
    (selectEvent)="backPayment()"
  ></gc-breadcrumb-bar>
  <div class="view-payment">
    <gc-view-payment [payment]="selectedPayment" url="/payments"></gc-view-payment>
  </div>
</div>
