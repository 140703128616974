<div class="toolbar" *ngIf="visible" [@slideInOut] [class.minimum]="alwaysVisible">
  <span class="title" [class.middle]="alwaysVisible">
    <a routerLink="/">
      <img *ngIf="alwaysVisible" [src]="desktopImgSrc" alt="myGC Logo" class="mobile-img" />
      <img *ngIf="!alwaysVisible" [src]="mobileImgSrc" alt="myGC Logo" class="mobile-img" />
    </a>
    <mat-icon *ngIf="!alwaysVisible" (click)="visible = false">close</mat-icon>
  </span>
  <div class="toolbar-link-container">
    <div class="toolbar-link-div">
      <div class="toolbar-link" [class.active]="linkActive(link)" *ngFor="let link of toolbarLinks$ | async" (click)="linkClicked(link)">
        <mat-icon
          aria-hidden="false"
          [matBadge]="(link.amountBadge | async) && (link.amountBadge | async) !== 0 ? '&#8288;' : ''"
          matBadgeColor="accent"
          matBadgeSize="small"
          [svgIcon]="link.icon"
        ></mat-icon>
        <span [innerHTML]="link.label"></span>
      </div>
    </div>
    <div *ngIf="loggedIn$ | async">
      <mat-divider></mat-divider>
      <div class="toolbar-link" [class.active]="linkActive(link)" *ngFor="let link of bottomLinks" (click)="showDialog(link.dialog)">
        <mat-icon class="link-icon" [svgIcon]="link.icon"></mat-icon>
        <span [innerHTML]="link.label" ngClass.gt-sm="desktop-bottom-link"></span>
      </div>
      <mat-divider></mat-divider>
      <div class="toolbar-link" (click)="logout()">
        <mat-icon class="link-icon" svgIcon="logout"></mat-icon>
        <span ngClass.gt-sm="desktop-bottom-link">Log out</span>
      </div>
    </div>
  </div>
</div>
