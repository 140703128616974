import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  isMobile$: BehaviorSubject<boolean>;

  originalPrimaryColor: string;
  originalAccentColor: string;

  constructor(public breakpointObserver: BreakpointObserver) {
    this.isMobile$ = new BehaviorSubject(false);
    this.breakpointObserver
      .observe(['(max-width: 959px)'])
      .pipe(distinctUntilChanged((prev, curr) => prev.matches === curr.matches))
      .subscribe((result) => {
        this.isMobile$.next(result.matches);
      });

    const rootStyles = getComputedStyle(document.documentElement);
    this.originalPrimaryColor = rootStyles.getPropertyValue('--primary-color').trim();
    this.originalAccentColor = rootStyles.getPropertyValue('--accent-color').trim();
  }

  changeTheme(primary: string, secondary: string) {
    document.documentElement.style.setProperty('--primary-color', primary);
    document.documentElement.style.setProperty('--accent-color', secondary);
  }

  resetTheme() {
    this.changeTheme(this.originalPrimaryColor, this.originalAccentColor);
  }
}
