import { Component, ElementRef, Inject, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Question, DEFAULT_QUESTION } from '@models/question.model';

export const DEFAULT_DROPDOWN: Question<any> = {
  ...DEFAULT_QUESTION,
  type: 'dropdown',
  optionValue: (v) => v,
  optionClass: () => '',
  optionLabel: (v) => v,
  optionHint: () => '',
};

export interface InputDialogData {
  title: string;
  tag?: string;
  width?: string;
  description?: string;
  cancelText?: string;
  hideCancel?: boolean;
  submitText?: string;
  questions: Question<any>[];
  cancelColour?: string;
  submitColour?: string;
}

@Component({
  selector: 'gc-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent implements OnInit, AfterViewInit {
  private static readonly DIALOG_ICONS = {
    edit: 'pencil',
    family: 'account-multiple-plus',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'consent-form': 'thumbs-up-down',
    access: 'lock',
    question: 'help-circle-outline',
  };

  private static readonly MAT_FORM_FIELD_TYPES = ['text', 'email', 'cellnr', 'signature', 'number', 'dropdown'];
  isSignature = false;
  @ViewChild('container', { static: false }) container: ElementRef<HTMLDivElement>;

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: InputDialogData,
    private readonly formBuilder: FormBuilder,
  ) {}

  get questions() {
    return this.data.questions;
  }

  get title() {
    return this.data.title;
  }

  get cancelText() {
    return this.data.cancelText ?? 'Cancel';
  }

  get hideCancel() {
    return this.data.hideCancel || false;
  }

  get submitText() {
    return this.data.submitText ?? 'OK';
  }

  get icon() {
    return InputDialogComponent.DIALOG_ICONS[this.data.tag];
  }

  get description() {
    return this.data.description;
  }

  get cancelColour() {
    return this.data.cancelColour;
  }

  get submitColour() {
    return this.data.submitColour ?? 'primary';
  }

  get width() {
    return this.data.width;
  }

  isQuestionTypeMatFormField(type: string) {
    return InputDialogComponent.MAT_FORM_FIELD_TYPES.includes(type);
  }

  ngOnInit() {
    this.data.questions = (this.data.questions || []).map((q) => {
      const prepop = q.type === 'dropdown' ? DEFAULT_DROPDOWN : DEFAULT_QUESTION;
      return { ...prepop, ...q };
    });
    this.form = this.formBuilder.group(this.groupQuestions(this.data.questions));
    const dataKeys = this.data.questions.map((q) => q.key);
    if (dataKeys.includes('signature')) {
      this.isSignature = true;
    }
  }

  ngAfterViewInit() {
    if (this.width) {
      this.container.nativeElement.style.width = this.width;
      this.container.nativeElement.style.maxWidth = this.width;
      this.container.nativeElement.style.minWidth = this.width;
    }
  }

  hintFor(question: Question<any>) {
    return question.optionHint?.(this.form.get(question.key).value);
  }

  classFor(question: Question<any>) {
    return question.optionClass?.(this.form.get(question.key).value);
  }

  private groupQuestions(questions: Question<any>[] = []): { [key: string]: FormControl } {
    const group = {};
    questions.forEach((q) => {
      const validators = [q.required ? Validators.required : null, q.regex ? Validators.pattern(q.regex) : null].filter((x) => x);
      group[q.key] = new FormControl(q.value, ...validators);
    });
    return group;
  }

  submit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
