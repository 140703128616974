<div class="content">
  <mat-toolbar class="greeting-toolbar">
    <span class="greeting">
      <mat-icon class="large-icon" [svgIcon]="greetingIcon$ | async"></mat-icon>
      <span>{{ greetingTitle$ | async }}</span>
    </span>
    <span class="name">{{ userName }}!</span>
  </mat-toolbar>
  <div class="section-a">
    <div class="section-a-left">
      <ng-container *ngTemplateOutlet="payments"></ng-container>
      <section>
        <div class="upcoming-bookings">
          <h1>{{ 'upcoming_bookings' | translate }}</h1>
          <div class="view-more uppercase">
            <span routerLink="/bookings/my-bookings">{{ 'view_more' | translate }}</span>
            <mat-icon svgIcon="chevron-right"></mat-icon>
          </div>
        </div>
        <div *ngIf="latestBookingList.length > 0; else loadingBookings" class="latest-bookings">
          <div *ngFor="let latestBooking of latestBookingList">
            <mat-card class="border">
              <gc-booking-display-card [booking]="latestBooking" (bookingDeleted)="bookingDeleted(latestBooking)"></gc-booking-display-card>
            </mat-card>
          </div>
        </div>
        <ng-template #loadingBookings>
          <div class="loading" *ngIf="isLoadingBooking; else noBooking">
            <span>{{ 'loading_bookings' | translate }}</span>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <ng-template #noBooking>
            <div>
              <div>{{ 'no_upcoming_bookings' | translate }}</div>
            </div>
          </ng-template>
        </ng-template>
      </section>
      <ng-container *ngTemplateOutlet="documents"></ng-container>
    </div>
    <section>
      <h1>{{ 'quick_actions' | translate }}</h1>
      <div class="quick-actions">
        <div *ngFor="let action of quickActions">
          <button mat-stroked-button class="outlined action" color="primary" [routerLink]="action.path">
            <mat-icon svgIcon="{{ action.icon }}"></mat-icon>
            <span>{{ action.label }}</span>
          </button>
        </div>
      </div>
    </section>
  </div>
  <ng-template #documents>
    <section class="section-a-left documents-group">
      <div class="upcoming-bookings">
        <h1>{{ 'incomplete_forms' | translate }}</h1>
        <div class="view-more uppercase" routerLink="/health">
          <span>{{ 'number_of_incomplete_forms' | translate : { numberForms } }}</span>
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <div *ngIf="!isLoadingForms; else loadingConsents">
        <mat-card *ngIf="recentConsent" class="form-card" matBadgeColor="accent">
          <span class="form">
            <div class="form-header">
              <mat-icon svgIcon="thumbs-up-down" color="primary"></mat-icon>
              <span class="form-body">
                <span class="title">{{ recentConsent.description }}</span>
                <span class="form-info">
                  <span>{{ 'from_doctor' | translate : { doctor: recentConsent.doctor } }}</span>
                  <span class="date">
                    {{ recentConsent.created | customDate : 'dd LLL yyyy' }}
                  </span>
                </span>
              </span>
            </div>
            <button mat-raised-button color="primary" [routerLink]="['/health', 'consent-forms', recentConsent.uuid]">
              <mat-icon svgIcon="pencil"></mat-icon>
              <span>{{ 'complete' | translate }}</span>
            </button>
          </span>
        </mat-card>
        <mat-card *ngIf="recentQuestionnaire" class="form-card" matBadgeColor="accent">
          <span class="form">
            <div class="form-header">
              <mat-icon svgIcon="clipboard-text" color="primary"></mat-icon>
              <span class="form-body">
                <span class="title">{{ recentQuestionnaire.title }}</span>
                <span class="form-info">
                  <span>{{ 'from_doctor' | translate : { doctor: recentQuestionnaire.doctor } }}</span>
                  <span class="date">
                    {{ recentQuestionnaire.timestamp | customDate : 'dd LLL yyyy' }}
                  </span>
                </span>
              </span>
            </div>
            <button mat-raised-button color="primary" [routerLink]="['/health', 'questionnaires', recentQuestionnaire.uuid]">
              <mat-icon svgIcon="pencil"></mat-icon>
              <span>{{ 'complete' | translate }}</span>
            </button>
          </span>
        </mat-card>
        <mat-card *ngIf="recentDemographicRequest" class="form-card" matBadgeColor="accent">
          <span class="form">
            <div class="form-header">
              <mat-icon svgIcon="heart-pulse" color="primary"></mat-icon>
              <span class="form-body">
                <span class="title">{{ 'demographic_requests' | translate }}</span>
                <span class="form-info">
                  <span>{{ 'from_doctor' | translate : { doctor: recentDemographicRequest.doctorName } }}</span>
                  <span class="date">
                    {{ recentDemographicRequest.requestedOn | customDate : 'dd LLL yyyy' }}
                  </span>
                </span>
              </span>
            </div>
            <button mat-raised-button color="primary" [routerLink]="['/health', 'demographic-requests', recentDemographicRequest.uuid]">
              <mat-icon svgIcon="pencil"></mat-icon>
              <span>{{ 'complete' | translate }}</span>
            </button>
          </span>
        </mat-card>
        <ng-template #noForms>
          <div>{{ 'no_outstanding_forms' | translate }}</div>
        </ng-template>
      </div>
      <ng-template #loadingConsents>
        <div class="loading">
          <span>{{ 'loading_forms' | translate }}</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </ng-template>
    </section>
  </ng-template>

  <ng-template #payments>
    <section>
      <div class="upcoming-bookings">
        <h1>{{ 'outstanding_payments' | translate }}</h1>
        <div class="view-more uppercase">
          <span routerLink="/payments">
            {{ 'view_number_more' | translate : { number_payments: numberPayments } }}
          </span>
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <div *ngIf="!isLoadingPayment; else loadingPayments">
        <ng-container *ngIf="recentPayment; else noPayments">
          <mat-card>
            <gc-payment-display-card [payment]="recentPayment"></gc-payment-display-card>
          </mat-card>
        </ng-container>
        <ng-template #noPayments>
          <div>{{ 'no_outstanding_payments' | translate }}</div>
        </ng-template>
      </div>
      <ng-template #loadingPayments>
        <div class="loading" *ngIf="isLoadingPayment; else noPayment">
          <span>{{ 'loading_payments' | translate }}</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-template #noPayment>
          <div>{{ 'no_outstanding_payments' | translate }}</div>
        </ng-template>
      </ng-template>
    </section>
  </ng-template>
</div>
