import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class PwaInstallService {
  deferredPrompt: BeforeInstallPromptEvent;
  installed = false;

  get promptUser() {
    const dismissedUntil = localStorage.getItem('installDismissedUntil');
    return (
      !!this.deferredPrompt &&
      !this.installed &&
      (!dismissedUntil || DateTime.fromISO(dismissedUntil).toMillis() < DateTime.now().toMillis())
    );
  }

  install(): void {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
    }
  }

  dismiss(): void {
    this.deferredPrompt = null;
    const until = DateTime.now().plus({ days: 2 });
    localStorage.setItem('installDismissedUntil', until.toISO());
  }
}
