import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { saveAs } from 'file-saver';
import { AuthService } from '@services/auth.service';
import { PackageService } from '@services/package.service';
import { DateTime } from 'luxon';

interface SuccessResponse {
  success: boolean;
  similarity: number;
}
interface TreatingDoctor {
  fullname?: string;
  practice?: string;
  qualification?: string;
  samdc?: string;
}
interface EventPdf {
  event_pdf?: string;
  user_id?: number;
  patient_cellnr?: string;
  patient_email?: string;
  patient_name?: string;
  patient_surname?: string;
  report_description?: string;
  report_name?: string;
  treating_doctor?: TreatingDoctor;
  create_date: DateTime;
}

@Component({
  templateUrl: './workflow-pdf.component.html',
  styleUrls: ['./workflow-pdf.component.scss'],
})
export class WorkflowPdfComponent implements OnInit {
  uuid: string;
  pdfDataUrl: string;
  pdf: EventPdf;
  pagesTotal = 0;
  currentPage = 1;
  zoomScale: string;
  isLoading = false;
  isPdfLoaded = false;
  isClaimed = false;
  noResults = undefined;
  constructor(
    private readonly http: HttpClient,
    private readonly currentRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly packageService: PackageService,
  ) {}

  ngOnInit(): void {
    const resolverId = this.currentRoute.snapshot.data?.identifier;
    const id = resolverId || this.packageService.identifier;
    this.uuid = this.currentRoute.snapshot.paramMap.get('uuid');
    // 768px is a common breakpoint for desktop screens.
    // Fit width for desktop, fit page for mobile
    if (window.innerWidth > 768) {
      this.zoomScale = 'page-width';
    } else {
      this.zoomScale = 'page-fit';
    }
    if (this.uuid) {
      this.http
        .get<EventPdf | SuccessResponse>(`/api/clinical/pdf_event/verify/${this.uuid}`, {
          params: {
            id,
          },
        })
        .subscribe((data) => {
          if ('similarity' in data) {
            this.noResults = true;
          } else {
            this.noResults = false;
            this.pdf = data;
            this.pdfDataUrl = data.event_pdf || '';
            this.isClaimed = data.user_id ? true : false;
            if (!this.pdfDataUrl) {
              this.pagesTotal = 0;
              this.currentPage = 0;
            }
          }
          this.isLoading = false;
        });

      if (this.currentRoute.snapshot.queryParams.claim && this.isLoggedIn()) {
        this.claimEvent();
      }
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pagesTotal = pdf.numPages;
    this.isPdfLoaded = true;
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage() {
    if (this.currentPage < this.pagesTotal) {
      this.currentPage++;
    }
  }

  downloadPdf() {
    saveAs(this.pdfDataUrl, this.pdf.report_name || `myGC report ${DateTime.now().toISODate()}.pdf`);
  }

  claimEvent() {
    if (!this.isLoggedIn()) {
      this.login();
    } else {
      this.http
        .post<{ success: boolean }>(`/api/clinical/pdf_event/claim/${this.uuid}`, {
          user_id: this.auth.getUserId(),
          identifier: this.packageService.identifier,
        })
        .subscribe((data) => {
          if (data && data.success) {
            this.isClaimed = true;
          }
        });
    }
  }

  isLoggedIn(): boolean {
    return this.auth.isAuthenticated();
  }

  login(withClaim = true) {
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: withClaim ? window.location.pathname + '?claim=true' : window.location.pathname },
    });
  }

  retry() {
    window.location.reload();
  }
}
