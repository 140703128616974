import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TitleService } from '@services/title.service';

@Injectable({
  providedIn: 'root',
})
export class TitleGuard {
  constructor(private readonly title: TitleService) {}

  canActivate(next: ActivatedRouteSnapshot): true {
    this.title.setTitle(next.data.title);
    return true;
  }
  canActivateChild(next: ActivatedRouteSnapshot): true {
    return this.canActivate(next);
  }
}
