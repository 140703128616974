<div class="body">
  <div class="main-box">
    <img class="img" src="assets/images/Oops-error.svg" alt="Oops-error" />
    <div class="error_number">404</div>
    <div class="title">Page not found</div>
    <div class="msg">
      The page you are looking for doesn't exist or another error occured.
      <br />
      <br />
      This is what you can do about it:
    </div>
    <div>
      <button mat-stroked-button class="action-button" routerLink="/doctors">
        <mat-icon svgIcon="magnify"></mat-icon>
        SEARCH FOR A DOCTOR
      </button>
      <br />
    </div>
    <div>
      <button mat-stroked-button class="return-button" routerLink="/">
        <mat-icon svgIcon="home"></mat-icon>
        GO TO HOME
      </button>
    </div>
    <div>
      <button mat-stroked-button class="return-button" (click)="location.back()">
        <mat-icon svgIcon="arrow-left"></mat-icon>
        GO BACK
      </button>
    </div>
  </div>
</div>
