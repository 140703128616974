import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Location } from '@angular/common';
import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { feedbackTypes } from '@modules/shared/dialogs/feedback-dialog/feedback-dialog.component';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { FeedbackService } from '@services/feedback.service';
import { catchError, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'gc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Output() loginSuccessful = new EventEmitter<void>();
  loginForm: FormGroup;
  showPassword = false;

  get shouldLog(): boolean {
    return window.location.pathname.includes('external');
  }

  get doctorUuid(): string {
    return this.route.snapshot.paramMap.get('identifier');
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    public location: Location,
    private readonly alert: AlertService,
    private readonly router: Router,
    private readonly feedbackService: FeedbackService,
    private readonly zone: NgZone,
    private readonly socialService: SocialAuthService,
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      identifier: ['', Validators.required],
      password: ['', Validators.required],
      remember: [true],
    });

    this.socialService.authState.subscribe((user) => {
      this.auth.postGoogleSignInCredentials(user.idToken);
      this.signInWithGoogle();
    });
  }

  login() {
    const { identifier, password, remember } = this.loginForm.value;
    this.auth
      .login$(identifier, password, remember)
      .pipe(
        catchError((err: unknown) => {
          this.alert.handleErrorDialog$(err, 'Unable to login');
          return null;
        }),
      )
      .subscribe((success) => {
        if (success) {
          this.zone.run(() => {
            this.loginSuccessful.emit();
          });
        }
      });
  }

  signInWithGoogle() {
    this.auth.authenticated$.pipe(distinctUntilChanged()).subscribe((authenticated) => {
      if (authenticated) {
        this.zone.run(() => {
          this.loginSuccessful.emit();
        });
      }
    });
  }

  feedback() {
    this.feedbackService.feedbackDialog(feedbackTypes.login).subscribe();
  }

  forgetPasswordClick() {
    this.router.navigate(['/forgot-password'], { queryParams: { returnUrl: this.router.url } });
  }
}
