<ng-template *ngIf="(minimal && doctor.hasBookings) || (isMobile$ | async) === false" [ngTemplateOutlet]="CardHeader"></ng-template>
<div class="no-panel-padding-card" *ngIf="!(minimal && doctor.hasBookings) && (isMobile$ | async)">
  <ng-template [ngTemplateOutlet]="CardHeader"></ng-template>
</div>
<ng-template #CardHeader>
  <div class="doctor-card">
    <div class="profile-picture-container" (click)="saveDoctor()">
      <gc-profile-picture [ngClass]="{ primary: doctor.uuid }" [fullName]="doctor.fullName" [imgSrc]="doctor.logo"></gc-profile-picture>
      <mat-icon
        *ngIf="isAuthed$ | async"
        [class.primary]="!doctor.uuid"
        [class.secondary]="doctor.uuid"
        [class.selected]="isSaved"
        svgIcon="star"
      ></mat-icon>
    </div>
    <div class="doctor-row">
      <div class="doctor-details">
        <div class="mat-overline">
          <span *ngIf="doctor.distance">{{ doctor.distance | distance }} away</span>
          <span *ngIf="doctor.practiceName" title="Practice Details">{{ doctor.practiceName }}</span>
          <ng-container *ngIf="!minimal">
            <button
              mat-icon-button
              class="extra-small"
              color="primary"
              *ngFor="let bookingType of filterBookingTypes(doctor.bookingTypes)"
              matTooltipPosition="above"
              matTooltipClass="tooltip-primary"
            >
              <mat-icon [svgIcon]="bookingType.icon"></mat-icon>
            </button>
          </ng-container>
        </div>
        <span class="centered">
          <span class="doctor-name">{{ doctor.diaryName }}</span>
          <span class="doctor-service">{{ doctor.service }}</span>
          <span *ngIf="timezone" class="doctor-timzone">Time zone: {{ timezone }}</span>
        </span>
      </div>
      <gc-doctor-actions class="hide-md" class="action-card" [doctor]="doctor"></gc-doctor-actions>
    </div>
    <div>
      <div *ngIf="minimal">
        <button
          *ngIf="doctor.uuid; else NoBookings"
          mat-mini-fab
          class="minimal-make-booking"
          type="button"
          (click)="makeBooking(doctor.uuid)"
        >
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </button>
        <ng-template #NoBookings>
          <gc-doctor-actions class="hide-md" [doctor]="doctor"></gc-doctor-actions>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
