<div class="about-screen">
  <img class="mygc-logo" src="assets/images/myGCLogo_small_icon.svg" alt="myGC Logo" />
  <div class="mygc-header">myGrandCentral</div>
  <div class="version-text">Latest version & release date:</div>
  <div class="version-text version-and-date">
    <span>Version {{ version }}</span>
    on
    <span>{{ date | customDate : 'dd LLL yyyy' }}</span>
  </div>
  <a class="release-notes-button" mat-button mat-dialog-close href="http://updates.goodxcloud.co.za/#mygcapp" target="_blank">
    Read latest release notes
  </a>
  <mat-divider class="divider"></mat-divider>
  <div class="powered-by">Powered by:</div>
  <div class="width-50">
    <img src="assets/images/goodx-logo.png" alt="GoodX Logo" />
  </div>
  <div class="width-50">
    <img src="assets/images/quant-logo.png" alt="Quant Engineering Logo" />
  </div>
  <button mat-button mat-dialog-close class="close">Close</button>
</div>
