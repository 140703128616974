<button *ngIf="getIcon()" mat-raised-button (click)="toggleFestiveIcons()" class="toggle-icons">
  <span class="icon">{{ getIcon() }}</span>
  <span>Effect {{ showFestive ? 'on' : 'off' }}</span>
</button>
<div *ngIf="showFestive">
  <div *ngFor="let item of [].constructor(13)" class="season-theme">
    <div class="inner">{{ getIcon() }}</div>
  </div>
</div>

<router-outlet></router-outlet>
