<div class="register-second-step">
  <mat-card class="have-account">
    <div class="img-desktop">
      <img src="assets/images/myGCLogo_default-white.svg" alt="myGC Logo" />
    </div>
    <div>
      <img src="assets/images/PIN_for-dark-bg.svg" alt="myGC Logo" />
    </div>
  </mat-card>
  <mat-card class="verify-number">
    <mat-card-header>
      <span class="span-hide">Step 2 of 2:</span>
      <span>Verify your number</span>
    </mat-card-header>
    <div *ngIf="!mobileNumberVerified" class="otp-card">
      <div class="image-mobile">
        <img src="assets/images/PIN_for-light-bg.svg" alt="myGC Logo" />
      </div>
      <p class="otp-label">Please enter the PIN sent to verify your mobile number</p>
      <div>
        <form [formGroup]="mobileNumberForm" style="display: flex; flex-direction: column; justify-content: center">
          <mat-form-field>
            <mat-label>Your mobile number</mat-label>
            <input matInput type="text" name="number" formControlName="number" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>One Time Pin (OTP)</mat-label>
            <input
              gcAutofocus
              matInput
              type="text"
              name="otp"
              class="uppercase"
              autocomplete="one-time-pin"
              [formControl]="otpControl"
              maxlength="4"
            />
          </mat-form-field>
        </form>
      </div>
      <button class="resend-btn" color="primary" mat-raised-button (click)="resendOtp()">
        <span *ngIf="!displayTimer">Resend PIN</span>
        <countdown *ngIf="displayTimer" #countdown [config]="{ leftTime: 30 }" (event)="enableButton($event)"></countdown>
      </button>
    </div>
    <gc-terms-and-conditions class="terms-container" (acceptTerms)="termsAndConditions($event)"></gc-terms-and-conditions>
    <div class="footer desktop">
      <button mat-raised-button color="accent" type="button" (click)="logout()">
        <mat-icon svgIcon="lock"></mat-icon>
        <span>Logout</span>
      </button>
      <button mat-raised-button type="button" (click)="submit()" color="primary" [disabled]="invalid">Finish sign up</button>
    </div>
  </mat-card>
</div>
<div class="footer mobile">
  <gc-terms-and-conditions class="terms-box" (acceptTerms)="termsAndConditions($event)"></gc-terms-and-conditions>
  <div class="footer-btn">
    <button mat-raised-button color="accent" type="button" (click)="logout()">
      <mat-icon svgIcon="lock"></mat-icon>
      <span>Logout</span>
    </button>
    <div>
      <button class="finish-btn" mat-raised-button color="primary" type="button" (click)="submit()" [disabled]="invalid">
        Finish sign up
      </button>
    </div>
  </div>
</div>
