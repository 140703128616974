import { Address } from './address.model';
import { EmployerDetails } from './employer.model';
import { MedicalAid, MedicalAidData } from './medical-aid.model';

export interface UserData {
  id: number;
  email: string;
  title?: string;
  name: string;
  surname: string;
  initials: string;
  gender: string;
  dob: string;
  cellnr: string;
  homenr: string;
  worknr: string;
  idnumber?: string;
  idtype?: string;
  email_verified: boolean;
  cellnr_verified: boolean;
  medical_aid: MedicalAidData;
  profile_picture: string;
  identification_picture?: string;
  medical_aid_front?: string;
  medical_aid_back?: string;
  physical_address: Address;
  postal_address: Address;
  employer_details: EmployerDetails;
  nok_name: string;
  nok_number: string;
  terms_accepted: boolean;
  has_password: boolean;
  // ? Family to link the user to
  family?: {
    id?: number;
    medical_aid_family?: boolean;
    description?: string;
  };
}

export interface UserEditData {
  title?: string;
  name?: string;
  surname?: string;
  initials?: string;
  email?: string;
  cellnr?: string;
  homenr?: string;
  worknr?: string;
  idnumber?: string;
  idtype?: string;
  dob?: string;
  gender?: string;
  profile_picture?: string;
  identification_picture?: string;
  medical_aid_front?: string;
  medical_aid_back?: string;
  physical_address?: Address;
  postal_address?: Address;
  medical_aid?: {
    medical_aid_scheme_name: string;
    medical_aid_scheme_code: string;
    medical_aid_option_name: string;
    medical_aid_option_code: string;
    medical_aid_number: string;
    dependant_type: string;
    dependant_code: string;
  };
  employer_details?: EmployerDetails;
  nok_name?: string;
  nok_number?: string;
  terms_accepted?: boolean;
}

export class User {
  private readonly _id: number; // eslint-disable-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  email: string;
  title?: string;
  name: string;
  surname: string;
  initials: string;
  cellnr?: string;
  homenr: string;
  worknr: string;
  idnumber?: string;
  idtype?: string;
  gender: string;
  dob: string;
  emailVerified: boolean;
  cellnrVerified: boolean;
  medicalAid: MedicalAid;
  profilePicture: string;
  identificationPicture?: string;
  medicalAidFront: string;
  medicalAidBack: string;
  physicalAddress: Address;
  postalAddress: Address;
  employerDetails: EmployerDetails;
  nextOfKin: { name: string; number: string };
  termsAccepted: boolean;
  hasPassword: boolean;

  constructor(data: UserData) {
    this._id = data.id;
    this.email = data.email;
    this.title = data.title;
    this.name = data.name;
    this.surname = data.surname;
    this.initials = data.initials;
    this.cellnr = data.cellnr;
    this.homenr = data.homenr;
    this.worknr = data.worknr;
    this.idnumber = data.idnumber;
    this.idtype = data.idtype;
    this.cellnrVerified = data.cellnr_verified;
    this.emailVerified = data.email_verified;
    this.medicalAid = data.medical_aid && new MedicalAid(data.medical_aid);
    this.dob = data.dob;
    this.gender = data.gender;
    this.profilePicture = data.profile_picture;
    this.identificationPicture = data.identification_picture;
    this.medicalAidFront = data.medical_aid_front;
    this.medicalAidBack = data.medical_aid_back;
    this.physicalAddress = data.physical_address;
    this.postalAddress = data.postal_address;
    this.employerDetails = data.employer_details;
    this.nextOfKin = { name: data.nok_name, number: data.nok_number };
    this.termsAccepted = data.terms_accepted;
    this.hasPassword = data.has_password;
  }

  get id() {
    return this._id;
  }

  get fullName() {
    return `${this.name} ${this.surname}`;
  }

  get rawData(): UserData {
    return {
      id: this.id,
      email: this.email,
      title: this.title,
      name: this.name,
      surname: this.surname,
      initials: this.initials,
      gender: this.gender,
      dob: this.dob,
      cellnr: this.cellnr,
      homenr: this.homenr,
      worknr: this.worknr,
      idnumber: this.idnumber,
      idtype: this.idtype,
      email_verified: this.emailVerified,
      cellnr_verified: this.cellnrVerified,
      medical_aid: this.medicalAid?.rawData,
      profile_picture: this.profilePicture,
      identification_picture: this.identificationPicture,
      medical_aid_front: this.medicalAidFront,
      medical_aid_back: this.medicalAidBack,
      physical_address: this.physicalAddress,
      postal_address: this.postalAddress,
      employer_details: this.employerDetails,
      nok_name: this.nextOfKin.name,
      nok_number: this.nextOfKin.number,
      terms_accepted: this.termsAccepted,
      has_password: this.hasPassword,
    };
  }
}
