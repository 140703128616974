import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(protected readonly auth: AuthService, protected readonly router: Router) {}

  protected getRedirectDestination(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): UrlTree {
    return this.router.parseUrl('/landing');
  }

  protected shouldRedirect() {
    return this.auth.checkAuthentication().pipe(map((authenticated) => !authenticated));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.shouldRedirect().pipe(map((shouldRedirect) => (shouldRedirect ? this.getRedirectDestination(route, state) : true)));
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
