import { Component, OnDestroy, ViewChild } from '@angular/core';
import { WebSocketService } from '@services/websocket.service';
import { Subject } from 'rxjs';
import { TopNavBarComponent } from './components/topnavbar/topnavbar.component';

@Component({
  selector: 'gc-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
})
export class SessionComponent implements OnDestroy {
  @ViewChild('topNavBar') topNav: TopNavBarComponent;
  doBlur = false;
  private readonly destroyed$ = new Subject<void>();

  constructor(private readonly websocket: WebSocketService) {}

  ngOnDestroy() {
    this.destroyed$.next();
    this.websocket.close();
  }
}
