import { AfterViewInit, Component, Input } from '@angular/core';
import { Payment } from '@models/account.model';
import { ConfigLocaleService } from '@services/configLocale.service';
import { Observable, of, switchMap } from 'rxjs';
@Component({
  selector: 'gc-payment-display-card',
  templateUrl: './payment-display-card.component.html',
  styleUrls: ['./payment-display-card.component.scss'],
})
export class PaymentDisplayCardComponent implements AfterViewInit {
  @Input() payment: Payment;

  currencyCode$: Observable<string>;

  constructor(private readonly configService: ConfigLocaleService) {}

  ngAfterViewInit() {
    this.currencyCode$ = this.configService
      .getValueByKey$(['currency_code'])
      .pipe(switchMap((currencyConfig) => of(currencyConfig.currency_code)));
  }
}
