import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(value: unknown, formatType: string = 'dd LLL yyyy'): unknown {
    const dateTimeValue = value as DateTime;
    if (dateTimeValue && dateTimeValue.isValid) {
      const datePipe = new DatePipe(this.translateService.currentLang || 'en-ZA');
      return datePipe.transform(dateTimeValue.toJSDate(), formatType);
    }
    return null;
  }
}
