<div class="toolbar">
  <img src="assets/images/myGCLogo_default-white.svg" alt="myGC Logo" routerLink="/" />
</div>
<div class="contents">
  <div *ngIf="privacyPolicy" [innerHTML]="privacyPolicy.html_value"></div>
  <!-- This is the LAST resort, if the request fails connect to the db we will default to RSA -->
  <div *ngIf="!privacyPolicy">
    <h2>DATA PROTECTION</h2>
    <ol>
      <li>
        The Protection of Personal Information Act (POPI), No 4 of 2013 promotes the protection of personal information by public and
        private bodies.
      </li>
      <li>
        For the App to work and to be able to administer your profile, we need to collect and process the personal information you provided
        to us.
      </li>
      <li>
        In accordance with the Protection of Personal Information Act (POPIA), you hereby consent to the disclosure of your information to
        myGC. myGC, along with its employees, agents, or authorized officials, may use and process this information for the purpose of
        providing the service. Additionally, myGC may disclose this information to third parties, such as Managed Service Providers (MSPs)
        or other entities of your choice, to the extent permitted by you.
      </li>
      <li>
        You further consent that generic information, not in any manner personally identifiable with you, may be used for statistical and
        research purposes.
      </li>
      <li>
        An MSP that contracts with myGC for purposes of the service and having access to your information with your permission, have to
        agree to comply with strict privacy policies and undertakes to ensure any employee, official agent or entity that deals with such
        information on behalf of the MSP, is bound by the same confidentiality policies.
      </li>
      <li>
        myGC undertakes to use its best endeavours and apply the highest standards of security as may be relevant in the industry to
        information of this nature, to ensure the safety, security and confidentiality of any information submitted to it by the user or MSP
        relating to the user.
      </li>
      <li>
        myGC will only send such messages, whether by text (SMS), e-mail or otherwise to you as may be necessary to effectively render the
        service to you, which may include information about updates or other requirements. Your information will not, unless you consent
        thereto, be provided to any third party for purposes of marketing, advertising or otherwise. If you give such consent, it may be
        withdrawn at any time by informing myGC accordingly.
      </li>
      <li>
        The user will at all times (except in the event of circumstances beyond the control of myGC such as interruption in ISP services)
        have access to and be able to determine the nature and extent of any information pertaining to the user held by and on behalf of the
        user. Should there be any uncertainty or queries in this regard, the user will be able to contact myGC at the web address at the
        hours of business as indicated there.
      </li>
      <li>
        The user must pay attention to and take note of requests for elections to be made or permissions to be granted from time to time
        within the app and make such elections or give such permissions with due attention to what is being requested.
      </li>
    </ol>
  </div>
</div>
