<div class="telehealth-overlay">
  <div class="title-div">
    <mat-icon svgIcon="information-outline"></mat-icon>
    <h1 mat-dialog-title>Welcome to Telehealth Now!</h1>
  </div>
  <div>
    <ol class="instruction-list">
      <li>Click on the "Start call" button when you're ready.</li>
      <li>Wait for the doctor to connect to the call.</li>
      <li>Ensure you have a good internet connection to ensure a successful video consultation.</li>
      <li>If you are calling on behalf of the patient, please ensure the patient is available for the call.</li>
    </ol>
  </div>
  <div class="button-div">
    <button mat-flat-button mat-dialog-close>Okay, let's start!</button>
  </div>
</div>
