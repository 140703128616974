import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Doctor } from '@models/doctor.model';
import { DoctorService } from '@services/doctor.service';
// eslint-disable-next-line @typescript-eslint/naming-convention
import Fuse from 'fuse.js';
import { concat, from, Observable } from 'rxjs';
import { map, mergeAll, switchMap } from 'rxjs/operators';
import { SearchableRoute, SearchItem, SearchOverlayRef, SEARCH_TERM } from '../models/search.model';

interface SearchOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  searchTerm?: string;
  component: ComponentType<any>;
}

const DEFAULT_CONFIG: SearchOverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'blur',
  component: null,
};

@Injectable({ providedIn: 'root' })
export class SearchService {
  constructor(
    private readonly injector: Injector,
    private readonly doctorService: DoctorService,
    private readonly overlay: Overlay,
    private readonly location: Location,
    private readonly router: Router,
  ) {}

  getResults$(searchTerm: string): Observable<SearchItem<Doctor | SearchableRoute>> {
    const appRoutes$ = from(this.getRouteSearchItems()).pipe(switchMap((routes) => this.searchRoutes$(searchTerm, routes)));
    const doctors$ = searchTerm && searchTerm.length > 2 ? this.searchDoctors$(searchTerm) : from([]);
    return concat(doctors$, appRoutes$);
  }

  searchDoctors$(searchTerm: string): Observable<SearchItem<Doctor>> {
    return this.doctorService.getDoctors({ searchTerm }).pipe(
      mergeAll(),
      map((item: Doctor): SearchItem<Doctor> => ({ type: 'doctor', item })),
    );
  }

  private async getRouteSearchItems(): Promise<SearchableRoute[]> {
    const paths = [
      await import('../../../app-routing.module'),
      await import('@modules/doctor/doctor-routing.module'),
      await import('@modules/health/health-routing.module'),
      await import('@modules/setup/setup-routing.module'),
    ];
    const searchableRoutes = paths.flatMap((m) => m.SEARCHABLE_ROUTES);
    const searchItems: SearchableRoute[] = searchableRoutes.flatMap((r) =>
      r.items.map((i) => ({ path: r.path, keywords: r.keywords, ...i })),
    );
    return searchItems;
  }

  searchRoutes$(searchTerm: string, routes: SearchableRoute[]): Observable<SearchItem<SearchableRoute>> {
    let matchingRoutes: SearchableRoute[];
    if (searchTerm) {
      const fuseOptions = {
        minMatchCharLength: 3,
        shouldSort: true,
        findAllMatches: true,
        keys: ['keywords', 'phrase'],
        ignoreLocation: true,
      };
      const index = Fuse.createIndex(fuseOptions.keys, routes);
      const fuseSearcher = new Fuse(routes, fuseOptions, index);
      matchingRoutes = fuseSearcher.search(searchTerm).map((i) => i.item);
    } else {
      matchingRoutes = routes.slice(0, 4);
    }
    return from(matchingRoutes).pipe(map((item): SearchItem<SearchableRoute> => ({ type: 'route', item })));
  }

  showOverlay(config: SearchOverlayConfig) {
    const overlayConfig = { ...DEFAULT_CONFIG, ...config };

    const overlayRef = this.createOverlay(overlayConfig);
    const searchOverlayRef = new SearchOverlayRef(overlayRef);
    // The injector is necessary so that data can be passed to the search results component.
    const injector = this.createInjector(overlayConfig, searchOverlayRef);
    const portal = new ComponentPortal(config.component, null, injector);
    overlayRef.attach(portal);

    overlayRef.backdropClick().subscribe(() => searchOverlayRef.close());
    this.location.onUrlChange(() => searchOverlayRef.close());
    this.router.events.subscribe(() => searchOverlayRef.close());

    return searchOverlayRef;
  }

  private createOverlay(config: SearchOverlayConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: SearchOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      positionStrategy,
    });

    return overlayConfig;
  }

  private createInjector(config: SearchOverlayConfig, searchOverlayRef: SearchOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(SearchOverlayRef, searchOverlayRef);
    injectionTokens.set(SEARCH_TERM, config.searchTerm);
    return new PortalInjector(this.injector, injectionTokens);
  }
}
