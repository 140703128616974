<mat-toolbar class="toolbar">
  <mat-toolbar-row class="bar-actions">
    <span class="burger-group">
      <button mat-icon-button name="burger-menu" type="button" (click)="hamburgerClick.emit($event)">
        <mat-icon svgIcon="menu"></mat-icon>
      </button>
      <div class="heading" *ngIf="router.url === '/dashboard'; else subPage">
        <img class="mygc-logo" src="assets/images/myGCLogo_default-white.svg" alt="myGC Logo" />
      </div>
      <ng-template #subPage>
        <div class="heading">
          <mat-icon ngClass.gt-sm="big-icon" class="page-icon" [svgIcon]="icon"></mat-icon>
          <div class="hide-on-medium text">
            <span class="title">
              {{ title }}
            </span>
          </div>
        </div>
      </ng-template>
    </span>
    <div class="header-buttons">
      <button mat-icon-button *ngIf="updateAvailable$ | async" (click)="updateApplication()" matTooltip="Update Available">
        <mat-icon matBadge="!" matBadgeColor="accent" svgIcon="cellphone-arrow-down"></mat-icon>
      </button>
      <gc-search-input class="hide-on-medium" [formControl]="searchTerm" placeholder="Can we help you find something?"></gc-search-input>
      <button mat-icon-button type="button" class="hide-on-mobile" (click)="onSearchClick()">
        <mat-icon svgIcon="magnify"></mat-icon>
      </button>
      <div class="actions">
        <button mat-icon-button type="button" routerLink="/notifications">
          <mat-icon
            aria-hidden="false"
            [matBadge]="(amountOfUnreadNotifications$ | async) > 0 ? (amountOfUnreadNotifications$ | async) : null"
            matBadgeColor="accent"
            svgIcon="bell"
          ></mat-icon>
        </button>
        <button
          mat-icon-button
          class="profile-picture"
          *ngIf="profilePicture$ | async; else noProfilePicture"
          type="button"
          [matMenuTriggerFor]="extraMenu"
          type="button"
          (menuOpened)="dropdownMenuVisible.emit(true)"
          (menuClosed)="dropdownMenuVisible.emit(false)"
          id="topNavBarToggleButton"
        >
          <gc-profile-picture [imgSrc]="profilePicture$ | async" mat-card-avatar></gc-profile-picture>
        </button>
        <ng-template #noProfilePicture>
          <button
            mat-icon-button
            type="button"
            [matMenuTriggerFor]="extraMenu"
            type="button"
            (menuOpened)="dropdownMenuVisible.emit(true)"
            (menuClosed)="dropdownMenuVisible.emit(false)"
            id="topNavBarToggleButton"
          >
            <mat-icon svgIcon="account-circle"></mat-icon>
          </button>
        </ng-template>
        <mat-menu #extraMenu="matMenu">
          <button mat-menu-item [routerLink]="['/setup/my-account']">
            <mat-icon svgIcon="account-circle" color="primary"></mat-icon>
            <span>My Account</span>
          </button>
          <button mat-menu-item [routerLink]="['/payments']">
            <mat-icon svgIcon="credit-card-outline" color="primary"></mat-icon>
            <span>Payments</span>
          </button>
          <button mat-menu-item [routerLink]="['/setup/settings']">
            <mat-icon svgIcon="cog" color="primary"></mat-icon>
            <span>Settings</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon svgIcon="logout" color="primary"></mat-icon>
            <span>Log out</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
