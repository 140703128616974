import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FilterType, TypeFilterComponent } from '@components/type-filter/type-filter.component';
import { Payment } from '@models/account.model';
import { feedbackTypes } from '@modules/shared/dialogs/feedback-dialog/feedback-dialog.component';
import { ErrorResponse } from '@models/responses.model';
import { AccountService } from '@services/account.service';
import { AlertService } from '@services/alert.service';
import { DisplayService } from '@services/display.service';
import { FeedbackService } from '@services/feedback.service';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigLocaleService } from '@services/configLocale.service';

@Component({
  selector: 'gc-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translate(100%, -40px)' }),
        animate('200ms linear', style({ transform: 'translate(0%, -40px)' })),
      ]),
      transition(':leave', [animate('1ms linear', style({ transform: 'translate(100%, -40px)' }))]),
    ]),
    trigger('slideOutIn', [
      transition(':enter', [style({ transform: 'translateX(-100%)' }), animate('200ms linear', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('1ms linear', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
})
export class PaymentsComponent implements OnInit, AfterViewInit {
  static iconMapping = {
    paid: 'checkbox-marked-circle',
    unpaid: 'alert-circle',
  };
  _paymentsDataSource: MatTableDataSource<Payment> = new MatTableDataSource();
  payments$: Observable<Payment[]> = this._paymentsDataSource.connect();
  currencyCode$: Observable<string>;

  _payments: Payment[] = [];
  searchControl: FormControl = new FormControl();
  sort: MatSort;
  paginator: MatPaginator;
  @ViewChild('search') searchField: ElementRef;
  @ViewChild('typeFilter') typeFilter: TypeFilterComponent;

  isMobile$: BehaviorSubject<boolean>;
  searchMode = false;
  typeFilters: FilterType[] = [
    {
      title: 'Paid Invoices',
      type: 'paid',
      icon: PaymentsComponent.iconMapping.paid,
      selected: false,
    },
    {
      title: 'Unpaid Invoices',
      type: 'unpaid',
      icon: PaymentsComponent.iconMapping.unpaid,
      selected: true,
    },
  ];
  selectedPayment: Payment;
  step = 0;

  constructor(
    public account: AccountService,
    private readonly configService: ConfigLocaleService,
    private readonly ref: ChangeDetectorRef,
    public display: DisplayService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public alert: AlertService,
    private readonly feedback: FeedbackService,
  ) {}

  ngAfterViewInit() {
    this.currencyCode$ = this.configService
      .getValueByKey$(['currency_code'])
      .pipe(switchMap((currencyConfig) => of(currencyConfig.currency_code)));
  }

  @ViewChild(MatSort) set sortContent(sortContent: MatSort) {
    if (sortContent) {
      this.sort = sortContent;
      this._paymentsDataSource.sort = this.sort;
      this.ref.detectChanges();
    }
  }
  @ViewChild(MatPaginator) set pageContent(pageContent: MatPaginator) {
    if (pageContent) {
      this.paginator = pageContent;
      this._paymentsDataSource.paginator = this.paginator;
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
    if (this.step === this._payments.length) {
      this.step = 0;
    }
  }

  prevStep() {
    this.step--;
    if (this.step < 0) {
      this.step = this._payments.length - 1;
    }
  }

  ngOnInit(): void {
    this.isMobile$ = this.display.isMobile$;
    this._paymentsDataSource.filterPredicate = (data, filter) =>
      ['type', 'accountNumber', 'treatingDoctor'].some((f) => data[f].toLowerCase().indexOf(filter) !== -1);

    combineLatest([this.account.getPayments$(), this.route.queryParams])
      .pipe(
        switchMap(
          ([payments, params]: [Payment[], Params]) => {
            // Set Payments
            this._payments = payments;
            this._paymentsDataSource.data = payments;
            if (params.type?.toLowerCase() === 'success') {
              this.alert
                .messageDialog$(
                  'Payment Confirmation',
                  'Thank you for your payment! We will send a payment to your email address.' +
                    ' You can also access your paid invoices in the myGC Payments section.',
                  'confirm',
                )
                .toPromise()
                .then(() => {
                  this.feedback.feedbackDialog(feedbackTypes.payment)?.subscribe();
                });
            } else if (params.type?.toLowerCase() === 'cancel') {
              this.alert.messageDialog$('Payment Cancelled', 'Your payment was cancelled. please try again.', 'error');
            } else if (params.type?.toLowerCase() === 'fail') {
              this.alert.messageDialog$('Payment Failed', 'Your payment failed, please try again later.', 'error');
            } else if (params.hash) {
              this.selectedPayment = payments.find((x) => x.hash === params.hash);
            }
            return this.isMobile$;
          },
          (err: unknown) => {
            if ((err as ErrorResponse).error === 'payments/missing-fields') {
              this.alert.messageDialog$('Error', "Can't retrieve payments. No ID number on profile.", 'error');
            }
          },
        ),
      )
      .subscribe();

    this.searchControl.valueChanges.subscribe((searchTerm) => {
      this._paymentsDataSource.filter = searchTerm.trim().toLowerCase();
      this._paymentsDataSource.paginator.firstPage();
    });
  }

  onFilter(data: Payment[]) {
    this._paymentsDataSource.data = data;
  }

  selectPayment(payment: Payment) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        type: null,
        hash: payment.hash,
      },
      // preserve the existing query params in the route
      queryParamsHandling: 'merge',
      // do not trigger navigation
      skipLocationChange: true,
    });
  }

  backPayment() {
    this.selectedPayment = null;
  }
}
