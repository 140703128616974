import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BookingType } from '@models/booking-type.model';
import { Booking, BookingData } from '@models/booking.model';
import { Doctor } from '@models/doctor.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(private readonly http: HttpClient, private readonly router: Router) {}

  getBookings$(userId: number): Observable<Booking[]> {
    return this.http.get<BookingData[]>(`/api/bookings/${userId}`).pipe(map((bookings) => bookings.map((res) => new Booking(res))));
  }

  getUpcomingBooking$(userId: number): Observable<Booking[]> {
    return this.http.get<BookingData[]>(`/api/booking/upcoming/${userId}`).pipe(
      map((bookings) => bookings.map((res) => new Booking(res))),
      catchError((err: unknown) => {
        console.error(err);
        return of([]);
      }),
    );
  }

  getBooking$(uuid, identifier?): Observable<Booking> {
    if (identifier) {
      // Get booking with identifier
      return this.http.get<BookingData>(`/api/booking/${uuid}/external/${identifier}`).pipe(map((booking) => new Booking(booking)));
    } else {
      // Get booking from auth
      return this.http.get<BookingData>(`/api/booking/${uuid}`).pipe(map((booking) => new Booking(booking)));
    }
  }

  makeBooking$(doctor: Doctor, bookingUserId: number, bookingType: BookingType, date: string): Observable<Booking> {
    const body = {
      diary_uuid: doctor.uuid,
      booking_type_uuid: bookingType.uuid,
      booking_type_name: bookingType.name,
      telemed: bookingType.telemed,
      date_time: date,
      duration: bookingType.duration.minutes,
      is_external: !!this.router.url.startsWith('/external'),
    };
    return this.http.post<BookingData>(`/api/bookings/${bookingUserId}`, body).pipe(map((res) => new Booking({ doctor, ...res })));
  }

  cancelBooking$(uuid: string): Observable<boolean> {
    return this.http.patch(`/api/booking/${uuid}/cancel`, null).pipe(
      map((res) => !!res),
      catchError((err: unknown) => {
        console.error(err);
        return of(false);
      }),
    );
  }

  confirmBooking$(uuid: string): Observable<boolean> {
    return this.http.post(`/api/booking/${uuid}/confirm`, null).pipe(
      map((res) => !!res),
      catchError((err: unknown) => {
        console.error(err);
        return of(false);
      }),
    );
  }

  joinRoom$(telemedBooking: Booking): Observable<boolean> {
    return this.http.post(`/api/telemed/join/${telemedBooking.uuid}`, null).pipe(
      map((res) => !!res),
      catchError((err: unknown) => {
        console.error(err);
        return of(false);
      }),
    );
  }
}
