<div class="body">
  <!-- Filter for mobile -->
  <button
    (click)="markAllAsRead()"
    class="mobile-mark-all all-as-read-btn-mobile"
    mat-raised-button
    color="accent"
    *ngIf="display.isMobile$ | async"
  >
    MARK ALL AS READ
  </button>
  <div
    class="filter filter-mobile"
    [class.search-mode]="searchMode"
    *ngIf="display.isMobile$ | async"
    matSort
    matSortDisableClear="true"
    matSortActive="sentDate"
    matSortDirection="desc"
  >
    <div class="search">
      <mat-icon svgIcon="magnify" class="magnifier" (click)="searchMode = true"></mat-icon>
      <input gcAutofocus type="text" name="search" placeholder="Search notifications..." [formControl]="searchControl" />
      <mat-icon svgIcon="close" class="close-search" (click)="searchMode = false"></mat-icon>
    </div>
    <button mat-icon-button (click)="openMobileFilterPopup()">
      <mat-icon>filter_alt</mat-icon>
      <span class="mobile-filter-text">Filter</span>
    </button>
    <p mat-sort-header="sentDate" mat-sort-start="desc">Date</p>
  </div>
  <div class="hide-on-mobile">
    <div class="filter">
      <div class="filter-header">
        <gc-search-input [formControl]="searchControl" placeholder="Search notifications..."></gc-search-input>
        <h2 class="heading-1">Filter</h2>
      </div>
      <gc-type-filter [data]="_notifications" (filterCallback)="onFilter($event)" [types]="typeFilters"></gc-type-filter>
    </div>
  </div>
  <div class="notifications-container">
    <div class="notifications">
      <ng-container
        *ngIf="(display.isMobile$ | async) === false"
        matSort
        matSortDisableClear="true"
        matSortActive="sentDate"
        matSortDirection="desc"
      >
        <div class="all-as-read-date-desktop">
          <p mat-sort-header="sentDate" class="hide-on-mobile">Date</p>
          <button mat-button mat-raised-button color="accent" (click)="markAllAsRead()">MARK ALL AS READ</button>
        </div>
      </ng-container>
      <div *ngIf="notifications$ | async as notifications" class="notifications-list">
        <div
          class="notification"
          [class.unread]="!n.readDate"
          [class]="n.type"
          [class.action-required]="n.actionRequired"
          *ngFor="let n of notifications; let i = index"
        >
          <div class="date">
            {{ n.sentDate | date : 'fullDate' }}
          </div>
          <div class="header">
            <div class="title">
              <mat-icon [color]="n.actionRequired ? 'warn' : 'accent'" [svgIcon]="n.icon" class="icon"></mat-icon>
              <span class="description">{{ n.description }}</span>
            </div>
            <button class="action" mat-button (click)="onNotificationActionClick($event, n)">{{ n.actionName || 'view' }}</button>
          </div>
        </div>
        <div class="no-notification" *ngIf="!notifications.length">
          <h2>{{ noNotifications ? 'No new notifications' : 'Loading notifications...' }}</h2>
        </div>
      </div>
    </div>
    <mat-paginator class="bottom" [pageSizeOptions]="[10, 20, 100]"></mat-paginator>
  </div>
</div>
