<div class="search">
  <mat-icon svgIcon="magnify"></mat-icon>
  <input
    #field
    type="text"
    [placeholder]="placeholder"
    (input)="_onChange($event)"
    (change)="_onChange($event)"
    (blur)="_onBlur()"
    [value]="value"
  />
  <button mat-icon-button tabindex="-1" type="button" (click)="clear($event)" class="fit-icon">
    <mat-icon svgIcon="close" *ngIf="value"></mat-icon>
  </button>
</div>
