import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Payment } from '@models/account.model';
import { Booking } from '@models/booking.model';
import { ConsentForm } from '@models/consent-form.model';
import { DemographicRequest } from '@models/demographic-request.model';
import { FamilyMember } from '@models/family-member.model';
import { Notification } from '@models/notifications/notification.model';
import { Questionnaire } from '@models/questionnaire.model';
import { User } from '@models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@services/account.service';
import { BookingService } from '@services/booking.service';
import { ClinicalService } from '@services/clinical.service';
import { DisplayService } from '@services/display.service';
import { PwaInstallService } from '@services/pwa-install.service';
import { UserService } from '@services/user.service';
import { DateTime } from 'luxon';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { first, map, mergeAll, mergeMap, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  // Labels and icons used in 'Quick Actions'
  quickActions = [
    {
      label: this.translate.instant('book_an_appointment'),
      icon: 'calendar',
      path: '/bookings/find-a-doctor',
    },
    {
      label: this.translate.instant('see_my_upcoming_bookings'),
      icon: 'calendar-month',
      path: '/bookings/my-bookings',
    },
    {
      label: this.translate.instant('view_my_documents'),
      icon: 'file',
      path: '/health/clinical-documents',
    },
    {
      label: this.translate.instant('view_outstanding_payments'),
      icon: 'credit-card-outline',
      path: '/payments',
    },
  ];
  notifications$: Observable<Notification[]>;
  unsubscribe$ = new Subject<void>();
  profile$: Observable<User>;
  family$: Observable<FamilyMember[]>;
  isMobile$: BehaviorSubject<boolean>;
  latestBooking: Booking;
  numberPayments: number;
  recentPayment: Payment;
  numberForms: number = 0;
  recentConsent: ConsentForm;
  recentQuestionnaire: Questionnaire;
  recentDemographicRequest: DemographicRequest;
  isLoadingPayment: boolean;
  isLoadingBooking: boolean;
  isLoadingForms: boolean;
  latestBookingList: Booking[] = [];
  userName = '';
  greetingIcon$: Observable<string>;
  greetingTitle$: Observable<string>;

  constructor(
    private readonly accountService: AccountService,
    private readonly appRef: ApplicationRef,
    private readonly userService: UserService,
    private readonly display: DisplayService,
    private readonly bookingService: BookingService,
    private readonly clinicalService: ClinicalService,
    public readonly translate: TranslateService,
    public install: PwaInstallService,
  ) {}

  ngOnInit() {
    this.profile$ = this.userService.profile$;
    this.isMobile$ = this.display.isMobile$;
    this.isLoadingPayment = true;
    this.isLoadingBooking = true;
    this.isLoadingForms = true;
    this.latestBookingList = [];
    this.profile$
      .pipe(
        switchMap((user: User) => {
          this.userName = user.name;
          return this.clinicalService.getConsentForms(user.id).pipe(map((consents) => ({ user, consents })));
        }),
        switchMap(({ user, consents }) => {
          const uncompletedConsent = consents
            .filter((c) => !c.complete || c.revokable)
            .sort((a, b) => a.created.diff(b.created).milliseconds);
          this.recentConsent = uncompletedConsent[0];
          this.numberForms += uncompletedConsent.length || 0;

          return this.clinicalService.getQuestionnaires(user.id).pipe(map((questionnaires) => ({ user, questionnaires })));
        }),
        switchMap(({ user, questionnaires }) => {
          const uncompletedQuestionnaires = questionnaires
            .filter((q) => !q.complete)
            .sort((a, b) => a.timestamp.diff(b.timestamp).milliseconds);
          this.numberForms += uncompletedQuestionnaires.length || 0;
          this.recentQuestionnaire = uncompletedQuestionnaires[0];

          return this.clinicalService.getDemographicRequests(user.id);
        }),
        tap(() => (this.isLoadingForms = false)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((demographicRequests) => {
        const uncompletedDemographicRequests = demographicRequests
          .filter((d) => !d.complete)
          .sort((a, b) => a.requestedOn.diff(b.requestedOn).milliseconds);
        this.recentDemographicRequest = uncompletedDemographicRequests[0];
        this.numberForms += uncompletedDemographicRequests.length || 0;
      });
    this.userService.familyMembers$
      .pipe(
        mergeMap((familyMembers) => familyMembers.map((member) => this.bookingService.getUpcomingBooking$(member.id))),
        mergeAll(),
        map((bookingsArr) => bookingsArr.reduce((bookings, currentBookings) => bookings.concat(currentBookings), [])),
      )
      .subscribe((bookings) => {
        this.isLoadingBooking = false;

        this.latestBooking = bookings
          .filter((x) => x.date.diffNow().milliseconds > 0) // Upcoming Bookings
          .sort((a, b) => (a.date > b.date ? 1 : -1))[0]; // Earliest Booking
        if (this.latestBooking) {
          const index = this.latestBookingList.findIndex((object) => object.uuid === this.latestBooking.uuid);
          if (index === -1) {
            this.latestBookingList.push(this.latestBooking);
            this.latestBookingList.sort((a, b) => (a.date < b.date ? -1 : 1));
          }
        }
      });

    // Update title and icon every 5 minutes once application is stable
    const hours$ = this.appRef.isStable.pipe(
      first((stable) => stable),
      switchMap(() => interval(1000 * 5 * 60 /* a minute */)),
      startWith(0),
    );
    const hour = DateTime.now().hour;
    this.greetingTitle$ = hours$.pipe(map(() => this.getTimeIconAndString(hour).text));
    this.greetingIcon$ = hours$.pipe(map(() => this.getTimeIconAndString(hour).icon));

    this.accountService
      .getPayments$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((payments) => {
        this.isLoadingPayment = false;
        const unpaidPayments = payments
          .filter((x) => !x.paid) // Unpaid Payments
          .sort((a, b) => (a.invoiceDate < b.invoiceDate ? 1 : -1)); // Most Recent
        this.numberPayments = unpaidPayments?.length || 0;
        this.recentPayment = unpaidPayments[0];
      });
  }

  bookingDeleted(booking: Booking) {
    this.latestBookingList = this.latestBookingList.filter((object) => object.uuid !== booking.uuid);
  }

  getTimeIconAndString(hour: number) {
    if (hour < 5 || hour >= 18) {
      return {
        icon: 'weather-night',
        text: this.translate.instant('good_evening'),
      };
    } else if (hour < 12) {
      return {
        icon: 'weather-sunset',
        text: this.translate.instant('good_morning'),
      };
    } else if (hour < 18) {
      return {
        icon: 'weather-sunny',
        text: this.translate.instant('good_afternoon'),
      };
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
