import { ComponentType } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InputDialogComponent, InputDialogData } from '@dialogs/input-dialog/input-dialog.component';
import { FeedbackDialogComponent, FeedbackDialogData } from '@dialogs/feedback-dialog/feedback-dialog.component';
import { MessageDialogComponent, MessageDialogData } from '@dialogs/message-dialog/message-dialog.component';
import { LoginDialogComponent } from '@dialogs/login-dialog/login-dialog.component';
import { KioskBooking } from '@models/kiosk-booking.model';
import { ErrorResponse } from '@models/responses.model';
import { Observable } from 'rxjs';
import { RetryDialogComponent } from '@modules/shared/dialogs/retry-dialog/retry-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  readonly defaultAction: string = 'Close';
  readonly defaultDuration: number = 2000;
  readonly errorDuration: number = null;

  constructor(private readonly matSnackbar: MatSnackBar, private readonly dialog: MatDialog) {}

  handleErrorDialog$(err: unknown, detail: string): Observable<any> {
    if (err instanceof HttpErrorResponse) {
      return this.messageDialog$(err.message, detail, 'error');
    } else if ((err as ErrorResponse).detail) {
      return this.messageDialog$((err as ErrorResponse).message, (err as ErrorResponse).detail, 'error');
    } else if ((err as ErrorResponse).message) {
      return this.messageDialog$('Error', (err as ErrorResponse).message, 'error');
    } else {
      return this.messageDialog$('Please try again', detail, 'error');
    }
  }

  notifyError$(detail: string): Observable<any> {
    return this.messageDialog$('Please try again', detail, 'error');
  }

  snackbar(message: string, action = this.defaultAction, duration = this.defaultDuration) {
    const snackbarRef = this.matSnackbar.open(message, action, {
      duration,
    });
    return snackbarRef.onAction();
  }

  openDialog$<T>(component: ComponentType<T>, config: MatDialogConfig<any>): Observable<any> {
    const dialogConfig = { ...config, hasBackdrop: true, backdropClass: 'blur' };
    const dialogRef = this.dialog.open(component, dialogConfig);
    return dialogRef.afterClosed();
  }

  messageDialog$(message: string, detail?: string, tag?: string, confirmation = false, config: MatDialogConfig = {}): Observable<any> {
    config = { autoFocus: false, ...config };
    const data = { ...config.data, message, detail, tag, confirmation } as MessageDialogData;
    return this.openDialog$(MessageDialogComponent, { ...config, data });
  }

  inputDialog$(data: InputDialogData, config: MatDialogConfig = {}): Observable<any> {
    return this.openDialog$(InputDialogComponent, { ...config, data });
  }

  feedbackDialog$(data: FeedbackDialogData, config: MatDialogConfig = {}, booking: KioskBooking): Observable<any> {
    return this.openDialog$(FeedbackDialogComponent, { ...config, data: { ...data, booking } });
  }

  loginDialog$(config: MatDialogConfig = {}): Observable<any> {
    return this.openDialog$(LoginDialogComponent, { ...config });
  }

  confirmDialog$(
    message: string,
    { cancelText, hideCancel, confirmText, cancelColour, confirmColour, detail, tag = 'question' }: Partial<MessageDialogData> = {},
    config: MatDialogConfig = {},
  ): Observable<boolean> {
    const data: MessageDialogData = {
      confirmText,
      cancelText,
      hideCancel,
      confirmColour,
      cancelColour,
      message,
      detail,
      tag,
      confirmation: true,
    };
    return this.openDialog$(MessageDialogComponent, { ...config, data });
  }

  retryDialog$(title: string, message: string, config: MatDialogConfig = {}): Observable<boolean> {
    return this.openDialog$(RetryDialogComponent, { ...config, data: { title, message } });
  }
}
