import { DateTime } from 'luxon';
import { Notification, NotificationData } from './notification.model';

export class MedicalHistoryNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);
    this.actionName = 'View Access';
    this.actionRequired = !this.readDate;
    const requestDate = DateTime.fromISO(data?.medicalHistoryRequest?.requested_timestamp);
    this.description = [
      `${data?.medicalHistoryRequest?.doctor_name ?? 'A practitioner'} has requested your Medical History`,
      `on ${requestDate.toFormat('HH:mm')}`,
      `on ${requestDate.toFormat('cccc, dd LLLL')}`,
    ].join(' ');
  }
}
