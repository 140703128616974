import { DateTime } from 'luxon';
import { Notification, NotificationData } from './notification.model';

export class TelemedNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);
    const dateDifference = DateTime.fromISO(this.sentDate).diffNow(['days', 'hours', 'minutes']);
    if (dateDifference.hours < 0) {
      this.description = `Telemed booking of ${this.sentDate}.`;
    } else if (dateDifference.hours <= 12) {
      this.description = `Telemed booking in ${dateDifference.hours} hours and ${dateDifference.minutes} minutes.`;
    } else if (dateDifference.hours <= 24) {
      this.description = 'Telemed booking for tomorrow.';
    } else {
      this.description = `Telemed booking in ${dateDifference.days} days.`;
    }
    this.actionName = 'View';
    this.actionRequired = dateDifference.hours > 0;
  }
}
