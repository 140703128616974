import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { feedbackTypes, FeedbackDialogData } from '@dialogs/feedback-dialog/feedback-dialog.component';
import { Feedback, DEFAULT_RATING } from '@models/feedback.model';
import { KioskBooking } from '@models/kiosk-booking.model';
import { AlertService } from '@services/alert.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(
    private readonly http: HttpClient,
    private readonly deviceService: DeviceDetectorService,
    private readonly router: Router,
    private readonly alert: AlertService,
  ) {}

  feedbackDialog(type: feedbackTypes, context = {}, booking?: KioskBooking) {
    let dialogData: FeedbackDialogData;
    switch (type) {
      case feedbackTypes.general:
        dialogData = {
          title: 'Send us feedback',
          tag: 'feedback',
          questions: [DEFAULT_RATING, { label: 'Tell us why you feel this way...', required: false, key: 'comment' }],
        };
        break;
      case feedbackTypes.kiosk:
        dialogData = {
          title: 'How was your experience using this kiosk?',
          tag: 'feedback',
          questions: [DEFAULT_RATING, { label: 'Tell us why you feel this way...', required: false, key: 'comment' }],
        };
        break;
      case feedbackTypes.booking:
        dialogData = {
          title: 'How was your experience making a booking?',
          tag: 'feedback',
          questions: [DEFAULT_RATING, { label: 'Tell us why you feel this way...', required: false, key: 'comment' }],
        };
        break;
      case feedbackTypes.register:
        dialogData = {
          title: 'Struggling to Register?',
          tag: 'feedback',
          questions: [{ label: 'Please let us know how we can help', required: true, key: 'comment' }],
        };
        break;
      case feedbackTypes.login:
        dialogData = {
          title: 'Struggling to log in to your account?',
          tag: 'feedback',
          questions: [{ label: 'Please let us know how we can help', required: true, key: 'comment' }],
        };
        break;
      default:
        dialogData = {
          title: 'Send us feedback',
          tag: 'feedback',
          questions: [DEFAULT_RATING, { label: 'Comments (optional)', required: false, key: 'comment' }],
        };
        break;
    }
    const config: MatDialogConfig = { backdropClass: 'blur', hasBackdrop: true, minWidth: 300 };
    return this.alert.feedbackDialog$(dialogData, config, booking).pipe(
      filter((data) => !!data),
      tap((data) => {
        const name = booking ? booking.userName : data.name;
        const email = booking ? booking.userEmail : data.email;
        const cellnr = booking ? booking.userCellnr : data.cellnr;
        this._addFeedback(
          data.rating,
          data.contact,
          data.contactMethod,
          data.comment,
          type,
          name,
          email,
          cellnr,
          data.id_number,
          context,
        ).subscribe(() => this.alert.snackbar('Feedback submitted.'));
      }),
    );
  }

  private _addFeedback(
    rating: number,
    contact: string,
    contactMethod: string,
    comment?: string,
    type = feedbackTypes.general,
    name?: string,
    email?: string,
    cellnr?: string,
    idNumber?: string,
    existingContext = {},
  ) {
    const context = {
      ...existingContext,
      type,
      deviceInfo: this.deviceService.getDeviceInfo(),
      url: this.router.url,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
    return this.http
      .post<Feedback>('/api/feedback', { rating, contact, contactMethod, comment, name, email, cellnr, idNumber, context })
      .pipe(take(1));
  }
}
