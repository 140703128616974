<div #container class="input-dialog" [ngClass]="{ 'signature-pad': isSignature }">
  <h1 mat-dialog-title class="title-group">
    <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
    <span class="title">{{ title }}</span>
  </h1>
  <div mat-dialog-content class="content">
    <div class="description" *ngIf="description" [innerHTML]="description"></div>
    <form [formGroup]="form" (submit)="submit()">
      <ng-container *ngFor="let question of questions">
        <div *ngIf="question.show(form.value)" class="question-container">
          <mat-form-field *ngIf="isQuestionTypeMatFormField(question.type); else checkboxQuestion">
            <mat-label>{{ question.label }}</mat-label>
            <ng-container [ngSwitch]="question.type">
              <input *ngSwitchDefault matNativeControl [formControlName]="question.key" type="text" />
              <input *ngSwitchCase="'email'" matNativeControl [formControlName]="question.key" type="email" email />
              <input *ngSwitchCase="'cellnr'" matNativeControl [formControlName]="question.key" type="cellnr" cellnr />
              <ng-container *ngSwitchCase="'dropdown'">
                <mat-select #select [formControlName]="question.key">
                  <mat-option *ngFor="let opt of question.options" [value]="question.optionValue(opt)">
                    {{ question.optionLabel(opt) }}
                  </mat-option>
                </mat-select>
              </ng-container>
              <ng-container *ngSwitchCase="'signature'">
                <gc-signature-input [formControlName]="question.key"></gc-signature-input>
              </ng-container>
            </ng-container>
            <mat-hint *ngIf="question.type === 'signature'">Please sign here</mat-hint>
          </mat-form-field>
          <ng-template #checkboxQuestion>
            <ng-container [ngSwitch]="question.type">
              <mat-checkbox *ngSwitchCase="'checkbox'" [formControlName]="question.key">{{ question.label }}</mat-checkbox>
            </ng-container>
          </ng-template>
        </div>
      </ng-container>
    </form>
    <div *ngFor="let question of questions">
      <div class="hint" *ngIf="!!hintFor(question)">
        <div class="icon">
          <mat-icon svgIcon="information-outline"></mat-icon>
        </div>
        <div [innerHtml]="hintFor(question)" [class]="classFor(question)"></div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="actions" [class.right-align]="hideCancel">
    <button
      mat-button
      mat-dialog-close
      *ngIf="!hideCancel"
      class="cancel"
      tabindex="-1"
      [color]="cancelColour"
      [class.coloured]="!!cancelColour"
    >
      {{ cancelText }}
    </button>
    <button mat-button [color]="submitColour" [mat-dialog-close]="form.value" type="submit" [disabled]="!form.valid">
      {{ submitText }}
    </button>
  </div>
</div>
