<div class="pdf" *ngIf="isLoading === false">
  <div class="container">
    <div class="logo-backdrop">
      <div class="img-container" routerLink="/dashboard">
        <img src="assets/images/myGCLogo_default-white.svg" alt="myGC Logo" />
      </div>
      <div class="auth-tool">
        <button
          *ngIf="isLoggedIn()"
          mat-button
          type="button"
          routerLink="/dashboard"
          aria-label="Navigate to dashboard"
          class="auth-button"
        >
          <mat-icon svgIcon="home"></mat-icon>
          <span class="hide-on-mobile">Dashboard</span>
        </button>
        <button *ngIf="!isLoggedIn()" mat-button type="button" (click)="login()" aria-label="Navigate to login page" class="auth-button">
          <mat-icon svgIcon="home"></mat-icon>
          <span class="hide-on-mobile">Login</span>
        </button>
      </div>
    </div>
    <section class="main-content" *ngIf="noResults">
      <mat-card class="incorrect-card">
        <h3>Incorrect identification</h3>
        <p>The identification detail you entered does not match any of those provided for this document.</p>
        <mat-card-actions>
          <button mat-stroked-button color="primary" (click)="retry()">Retry Identification</button>
        </mat-card-actions>
      </mat-card>
    </section>
    <section class="main-content" *ngIf="noResults === false">
      <div class="pdf-left-side">
        <div class="pdf-container-and-controls">
          <div class="pdf-view-container">
            <pdf-viewer
              *ngIf="pdfDataUrl"
              [src]="pdfDataUrl"
              [original-size]="false"
              [autoresize]="true"
              [show-all]="false"
              [render-text]="false"
              [zoom-scale]="zoomScale"
              [page]="currentPage"
              [show-borders]="true"
              (after-load-complete)="afterLoadComplete($event)"
            ></pdf-viewer>
            <div *ngIf="!pdfDataUrl && isPdfLoaded">
              <h3>No document found</h3>
            </div>
            <div *ngIf="!isPdfLoaded">
              <mat-spinner diameter="50"></mat-spinner>
            </div>
          </div>
          <div class="page-number">
            <span>{{ currentPage }} of {{ pagesTotal }}</span>
          </div>
          <div class="button-controls">
            <button mat-icon-button color="primary" (click)="previousPage()"><mat-icon svgIcon="arrow-left"></mat-icon></button>
            <button mat-icon-button color="primary" (click)="nextPage()"><mat-icon svgIcon="arrow-right"></mat-icon></button>
          </div>
        </div>
        <div class="claim-buttons">
          <button mat-raised-button color="primary" (click)="downloadPdf()">
            Download
            <mat-icon svgIcon="download"></mat-icon>
          </button>
          <button mat-raised-button color="primary" (click)="claimEvent()" *ngIf="!isClaimed">
            Link to account
            <mat-icon svgIcon="import"></mat-icon>
          </button>
          <button mat-raised-button color="accent" *ngIf="isClaimed">
            Linked
            <mat-icon svgIcon="check"></mat-icon>
          </button>
        </div>
      </div>
      <div class="doctor-patient-container">
        <mat-card>
          <h3>Document Details</h3>
          <p>Create Date: {{ pdf.create_date.toString() | date : 'longDate' }}</p>
          <p>Document Title: {{ pdf.report_name }}</p>
          <p>Description: {{ pdf.report_description }}</p>
        </mat-card>
        <mat-card>
          <h3>Patient Details</h3>
          <p>Name: {{ pdf.patient_name }}</p>
          <p>Surname: {{ pdf.patient_surname }}</p>
          <p>Cellphone: {{ pdf.patient_cellnr }}</p>
          <p>Email: {{ pdf.patient_email }}</p>
        </mat-card>
        <mat-card>
          <h3>Treating Doctor</h3>
          <p *ngIf="pdf.treating_doctor">Name: {{ pdf.treating_doctor.fullname }}</p>
          <p *ngIf="!pdf.treating_doctor">Treating doctor not given.</p>
        </mat-card>
      </div>
    </section>
  </div>
</div>
<div *ngIf="isLoading === true" class="spinner">
  <mat-spinner diameter="50"></mat-spinner>
</div>
