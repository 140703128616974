import { DateTime } from 'luxon';

export interface ConsentFormData {
  uuid: string;
  user_id: number;
  description: string;
  body: string;
  doctor: string;
  created: string;
  signed: boolean;
  declined: boolean;
  declined_reason?: string;
  response_date?: string;
  signature?: string;
  signed_by_proxy: boolean;
  proxy_name?: string;
  proxy_idnumber?: string;
  proxy_contact_number?: string;
  revokable: boolean;
  health_window: boolean;
  revoked: boolean;
  revoked_reason?: string;
  revoked_date?: string;
  documents?: string[];
}

export class ConsentForm {
  readonly uuid: string;
  readonly userId: number;
  readonly description: string;
  readonly body: string;
  readonly doctor: string;
  readonly created: DateTime;
  readonly signed: boolean;
  readonly declined: boolean;
  readonly declinedReason: string;
  readonly responseDate: DateTime;
  readonly signature: string;
  readonly proxySigned: boolean;
  readonly proxyName: string;
  readonly proxyIdnumber: string;
  readonly proxyContact: string;
  readonly revokable: boolean;
  readonly healthWindow: boolean;
  readonly revoked: boolean;
  readonly revokedDate?: Date;
  readonly revokedReason?: string;
  readonly documents?: string[];

  constructor(data: ConsentFormData) {
    this.uuid = data.uuid;
    this.userId = data.user_id;
    this.description = data.description;
    this.body = data.body;
    this.doctor = data.doctor;
    this.created = DateTime.fromISO(data.created);
    this.signed = data.signed;
    this.signature = data.signature;
    this.proxySigned = data.signed_by_proxy;
    this.proxyName = data.proxy_name;
    this.proxyIdnumber = data.proxy_idnumber;
    this.proxyContact = data.proxy_contact_number;
    this.declined = data.declined;
    this.declinedReason = data.declined_reason;
    this.responseDate = data.response_date && DateTime.fromISO(data.response_date);
    this.revokable = data.revokable;
    this.healthWindow = data.health_window;
    this.revoked = data.revoked;
    this.revokedDate = data.revoked_date && DateTime.fromISO(data.revoked_date).toJSDate();
    this.revokedReason = data.revoked_reason;
    this.documents = data.documents;
  }

  get complete(): boolean {
    return this.signed || this.declined;
  }
}
