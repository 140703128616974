<div class="booking-display-card">
  <div class="info-content">
    <div class="info">
      <div class="names">
        <div class="patient-name">
          <gc-profile-picture [imgSrc]="booking.user.profilePicture" [fullName]="booking.user.fullName"></gc-profile-picture>
          {{ booking.user.fullName }}
        </div>
        <div class="flex-column">
          <div class="date">
            <mat-icon svgIcon="calendar" color="primary"></mat-icon>
            {{ booking.date | customDate : 'HH:mm' }} on {{ booking.date | customDate : 'cccc, dd LLLL yyyy  (ZZ)' }}
          </div>
          <div class="duration">
            <mat-icon [svgIcon]="displayIcon" color="primary"></mat-icon>
            {{ booking.type.name }} {{ showDuration(booking.type.duration) }}
          </div>
        </div>
        <div class="doctor-name">
          <gc-profile-picture
            class="logo"
            [ngClass]="{ primary: booking.doctor.uuid }"
            [fullName]="booking.doctor.fullName"
            [imgSrc]="booking.doctor.logo"
          ></gc-profile-picture>
          <div class="flex-column">
            <span class="bold">
              {{ booking.doctor.fullName || booking.doctor.diaryName }}
            </span>
            <span>{{ booking.doctor.service || 'GP' }}</span>
            <span class="time-zone">{{ timezone(booking.doctor) }}</span>
          </div>
        </div>
      </div>
      <div class="actions">
        <a
          *ngIf="booking.doctor.landlines?.length"
          mat-icon-button
          matTooltip="Tel: {{ booking.doctor.landlines?.[0] }}"
          href="tel:{{ booking.doctor.landlines?.[0] }}"
          class="booking-display-card-button small"
          target="_blank"
        >
          <mat-icon>phone</mat-icon>
        </a>
        <a
          *ngIf="booking.doctor.physicalAddress"
          mat-icon-button
          matTooltip="View on map"
          [href]="booking.doctor.googleMapsLink"
          class="booking-display-card-button small"
          target="_blank"
        >
          <mat-icon>location_on</mat-icon>
        </a>
        <a
          *ngIf="booking.doctor.emailAddress"
          mat-icon-button
          matTooltip="Email"
          href="mailto:{{ booking.doctor.emailAddress }}"
          class="booking-display-card-button small"
          target="_blank"
        >
          <mat-icon>email</mat-icon>
        </a>
      </div>
    </div>
    <div
      *ngIf="showActions"
      class="booking-action-row"
      [class.space-between]="hasDocuments(booking) || booking.type.telemed"
      [class.space-end]="!(hasDocuments(booking) || booking.type.telemed)"
    >
      <div class="purple-actions">
        <button mat-raised-button color="primary" *ngIf="hasDocuments(booking)" [routerLink]="['/p', booking.uuid, booking.user.surname]">
          <mat-icon svgIcon="folder-heart"></mat-icon>
          Documents
        </button>
        <button mat-raised-button color="primary" *ngIf="booking.type.telemed" (click)="openTelemed(booking.uuid)">
          <mat-icon svgIcon="video"></mat-icon>
          Join Call
        </button>
      </div>
      <button
        mat-stroked-button
        color="warn"
        type="button"
        class="cancel-button"
        *ngIf="isPastBooking(booking) && !cancelBookingTime"
        (click)="cancelBooking(booking.uuid, booking.date, booking.doctor.cancellationHours)"
      >
        Cancel Booking
      </button>
      <div *ngIf="cancelBookingTime">
        <span>Booking Cancelled on {{ cancelBookingTime | customDate : 'HH:mm cccc, dd LLLL yyyy  (ZZ)' }}</span>
      </div>
    </div>
  </div>
</div>
