import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
// Sidebar
import { SidebarNavComponent } from '@components/sidebar-nav/sidebar-nav.component';
import { SearchOverlayRef } from '@modules/session/models/search.model';
// Services
import { SearchService } from '@modules/session/services/search.service';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { NotificationService } from '@services/notification.service';
import { UpdateService } from '@services/update.service';
import { UserService } from '@services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { SearchResultsComponent } from '../search-results/search-results.component';

@Component({
  selector: 'gc-top-nav-bar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.scss'],
})
export class TopNavBarComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: SidebarNavComponent;
  @Output() dropdownMenuVisible = new EventEmitter<boolean>();
  unsubscribe$ = new Subject<void>();
  title: string;
  icon: string;
  userName$: Observable<string>;
  profilePicture$: Observable<string>;
  @Output() hamburgerClick = new EventEmitter<MouseEvent>();
  public searchTerm = new FormControl();
  private searchOverlay: SearchOverlayRef;
  amountOfUnreadNotifications$: Observable<number>;
  updateAvailable$: Observable<boolean>;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alert: AlertService,
    private readonly auth: AuthService,
    private readonly user: UserService,
    private readonly searchService: SearchService,
    private readonly notification: NotificationService,
    public router: Router,
    private readonly update: UpdateService,
    private readonly translate: TranslateService,
  ) {
    this.title = this.activatedRoute.firstChild?.routeConfig.data.title;
    this.icon = this.activatedRoute.firstChild?.routeConfig.data.icon;
  }

  ngOnInit(): void {
    // Get update status
    this.updateAvailable$ = this.update.updatesAvailable$;

    // Get the username for the logged in profile
    this.profilePicture$ = this.user.profile$.pipe(
      filter((user) => !!user),
      map((user) => user.profilePicture),
    );

    // Get Notifs
    this.amountOfUnreadNotifications$ = this.notification.amountOfUnreadNotifications$;

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.title = this.activatedRoute.firstChild?.routeConfig.data.title || 'Set your Title';
        this.icon = this.activatedRoute.firstChild?.routeConfig.data.icon || 'exclamation';
      }
    });

    this.searchTerm.valueChanges
      .pipe(
        filter((term) => term && term.length >= 3),
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe((searchTerm: string) => this.searchApp(searchTerm));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  onSearchClick() {
    this.searchApp('');
  }

  searchApp(searchTerm: string): void {
    if (this.searchOverlay) {
      this.closeSearchOverlay();
    }
    this.openSearchOverlay(searchTerm);
  }

  private openSearchOverlay(searchTerm: string): void {
    const component = SearchResultsComponent;
    this.searchOverlay = this.searchService.showOverlay({ searchTerm, component });
    this.searchOverlay.hasClosed.subscribe(() => (this.searchOverlay = undefined));
  }

  private closeSearchOverlay(): void {
    this.searchOverlay.close();
  }

  logout() {
    const confirmLogout = this.translate.instant('confirm_logout');
    const confirmLogoutMessage = this.translate.instant('confirm_logout_message');

    this.alert
      .messageDialog$(confirmLogout, confirmLogoutMessage, 'error', true)
      .pipe(
        filter((data) => !!data),
        tap(() => {
          this.auth.logout().subscribe(() => {
            this.router.navigate(['/landing']);
          });
        }),
      )
      .subscribe();
  }

  updateApplication() {
    this.update.activateUpdate();
  }
}
