<div class="growers">
  <button
    class="contact-icon"
    mat-icon-button
    [matMenuTriggerFor]="beforeMenu"
    matTooltip="Contact Details"
    *ngIf="doctor.landlines?.[0] || doctor.googleMapsLink || doctor.emailAddress || doctor.website"
  >
    <mat-icon svgIcon="dots-vertical"></mat-icon>
  </button>
  <mat-menu #beforeMenu="matMenu" xPosition="before">
    <div class="div-list">
      <ul>
        <li *ngIf="doctor.landlines?.length" class="list-spacing">
          <gc-contact icon="phone" [title]="doctor.landlines?.[0]" href="tel:{{ doctor.landlines?.[0] }}"></gc-contact>
        </li>
        <li *ngIf="doctor.physicalAddress" class="list-spacing">
          <gc-contact icon="map-marker" title="View on map" [href]="doctor.googleMapsLink"></gc-contact>
        </li>
        <li *ngIf="doctor.emailAddress" class="list-spacing">
          <gc-contact icon="email" title="Email" href="mailto:{{ doctor.emailAddress }}"></gc-contact>
        </li>
        <li *ngIf="doctor.website"><gc-contact icon="earth" title="Website" href="{{ doctor.website }}"></gc-contact></li>
      </ul>
    </div>
  </mat-menu>
</div>
