import { Component, OnInit } from '@angular/core';
import { ConfigLocaleService, PageConfig } from '@services/configLocale.service';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'gc-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPolicy: PageConfig;
  constructor(private readonly configLocaleService: ConfigLocaleService) {}

  ngOnInit(): void {
    this.configLocaleService
      .getPageByKey$('privacy_policy', { default: false })
      .pipe(catchError(() => of(null)))
      .subscribe((data) => {
        this.privacyPolicy = data;
      });
  }
}
