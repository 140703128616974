import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

// Guards
import { RedirectGuard } from '@guards/redirect.guard';

// Components
import { PaymentsComponent } from '@components/payments/payments.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { TelehealthComponent } from './pages/telehealth/telehealth.component';
import { SessionComponent } from './session.component';

const routes: Routes = [
  {
    path: '',
    component: SessionComponent,
    data: { title: 'Home' },
    canActivateChild: [RedirectGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' } as Route,
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard' },
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        data: {
          title: 'Notifications',
          icon: 'bell',
        },
      },
      {
        path: 'health',
        loadChildren: async () => import('../health/health.module').then((m) => m.HealthModule as any),
        data: {
          title: 'Health',
          icon: 'heart',
        },
      },
      {
        path: 'bookings',
        loadChildren: async () => import('../bookings/bookings.module').then((m) => m.BookingsModule as any),
        data: {
          title: 'Bookings',
          icon: 'stethoscope',
        },
      },
      {
        path: 'secure-message',
        loadChildren: async () => import('../secure-message/secure-message.module').then((m) => m.SecureMessageModule as any),
        data: {
          title: 'Secure Message',
          icon: 'forum-outline',
        },
      },
      {
        path: 'setup',
        loadChildren: async () => import('../setup/setup.module').then((m) => m.SetupModule as any),
        data: {
          title: 'Account',
          icon: 'wrench',
        },
      },
      {
        path: 'telehealth',
        component: TelehealthComponent,
        data: { title: 'Telehealth', icon: 'message-video' },
      },
      {
        path: 'payments',
        component: PaymentsComponent,
        data: {
          title: 'Payments',
          icon: 'credit-card-outline',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SessionRoutingModule {}
