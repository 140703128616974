import { Component, EventEmitter, Inject, Output, OnChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface FilterType<T = string> {
  title: string;
  type: T;
  icon: string;
  selected: boolean;
}

@Component({
  selector: 'gc-type-filter-dialog',
  templateUrl: './type-filter-dialog.component.html',
  styleUrls: ['./type-filter-dialog.component.scss'],
})
export class TypeFilterDialogComponent implements OnChanges {
  types: FilterType[] = [];
  data: any[] = [];
  minimal = false;
  @Output() filterCallback: EventEmitter<any> = new EventEmitter();
  filteredData = [];
  funcFilter: (n: any, s: string[]) => boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public injectedData: any, public dialogRef: MatDialogRef<TypeFilterDialogComponent>) {
    this.types = injectedData.types || [];
    this.data = injectedData.data || [];
    this.funcFilter = injectedData.funcFilter;
    if (injectedData.minimal !== undefined) {
      this.minimal = injectedData.minimal;
    }
  }

  ngOnChanges(): void {
    this.doFilter();
  }

  onToggleType(type: FilterType): void {
    type.selected = !type.selected;
    this.doFilter();
  }

  private doFilter(): void {
    const selectedTypes = this.types.filter((t) => t.selected).map((t) => t.type);
    this.filteredData = selectedTypes.length
      ? this.data.filter((n) => {
          if (this.funcFilter) {
            return this.funcFilter(n, selectedTypes);
          }
          return selectedTypes.includes(n.type);
        })
      : this.data;
    this.filterCallback.emit(this.filteredData);
  }

  clearToggles(): void {
    this.types.forEach((t) => (t.selected = false));
    this.filteredData = this.data;
    this.filterCallback.emit(this.filteredData);
  }

  onApply(): void {
    this.dialogRef.close(this.filteredData);
  }

  onRemoveAll(): void {
    this.clearToggles();
    this.dialogRef.close(this.data);
  }
}
