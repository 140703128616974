import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@services/auth.service';
import { KioskBooking } from '@models/kiosk-booking.model';
import { Question, DEFAULT_QUESTION } from '@models/question.model';
import { ReplaySubject } from 'rxjs';
import { User } from '@models/user.model';
import { UserService } from '@services/user.service';
import { ConfigLocaleService, ConfigRegex } from '@services/configLocale.service';

export enum feedbackTypes {
  general = 'GENERAL',
  booking = 'BOOKING',
  kiosk = 'KIOSK',
  register = 'REGISTER',
  login = 'LOGIN',
  consent = 'CONSENT_FORM',
  payment = 'PAYMENT_LINK',
  customForm = 'CUSTOM_FORM',
  telemed = 'TELEMED',
  package = 'PACKAGE',
}

export interface FeedbackDialogData {
  title: string;
  tag?: string;
  booking?: KioskBooking;
  questions: Question<any>[];
  submitColour?: string;
}

@Component({
  selector: 'gc-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit {
  private static readonly DIALOG_ICONS = {
    edit: 'pencil',
    family: 'account-multiple-plus',
    feedback: 'send',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'consent-form': 'thumbs-up-down',
    access: 'lock',
    question: 'help-circle-outline',
  };

  private static readonly MAT_FORM_FIELD_TYPES = ['text', 'email', 'cellnr', 'signature', 'number'];

  form: FormGroup;
  private _isAuth: boolean;
  private _questions: Question<any>[];
  private contactUser: boolean;
  private isEmail: boolean;
  profile$: ReplaySubject<User>;
  constructor(
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly auth: AuthService,
    @Inject(MAT_DIALOG_DATA) private readonly data: FeedbackDialogData,
    private readonly userService: UserService,
    private readonly configService: ConfigLocaleService,
  ) {}

  get questions() {
    return this._questions;
  }

  get title() {
    return this.data.title;
  }

  get icon() {
    return FeedbackDialogComponent.DIALOG_ICONS[this.data.tag];
  }

  get hideContactMethod() {
    return this.contactUser;
  }

  get isAuth() {
    return this._isAuth;
  }

  get isEmailSelected() {
    return this.isEmail;
  }

  toggleContactMethod(value: boolean) {
    if (!this.isAuth) {
      this.form.controls.email.setValidators(value ? Validators.compose([Validators.email]) : null);
      this.form.controls.email.addValidators(Validators.required);
      this.form.controls.email.updateValueAndValidity();
      this.form.controls.cellnr.setValidators(null);
      this.form.controls.cellnr.updateValueAndValidity();
    }
    if (value) {
      this.contactUser = false;
      this.isEmail = true;
      this.form.controls.contactMethod.setValue('email');
    } else {
      this.contactUser = true;
      this.form.controls.email.removeValidators(Validators.required);
      this.form.controls.cellnr.removeValidators(Validators.required);
      this.form.controls.cellnr.updateValueAndValidity();
      this.form.controls.email.updateValueAndValidity();
    }
  }

  toggleEmailPhone(value: string) {
    this.isEmail = value === 'email';
    if (!this.isAuth) {
      const emailControl = this.form.controls.email;
      const cellnrControl = this.form.controls.cellnr;

      emailControl.setValidators(this.isEmail ? [Validators.required, Validators.email] : null);
      emailControl.updateValueAndValidity();

      this.configService.getValueByKey$(['cell_validators_json']).subscribe((config) => {
        const cellValidators = JSON.parse(config.cell_validators_json) as ConfigRegex;
        cellnrControl.setValidators(!this.isEmail ? [Validators.pattern(cellValidators.pattern)] : null);
        if (cellValidators.required && !this.isEmail) {
          cellnrControl.addValidators(Validators.required);
        }
        cellnrControl.updateValueAndValidity();
      });
    }
  }

  isQuestionTypeMatFormField(type: string) {
    return FeedbackDialogComponent.MAT_FORM_FIELD_TYPES.includes(type);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      contact: [true, Validators.required],
      contactMethod: ['email', Validators.required],
    });
    this.addAuthenticatedComponents();
    this._questions = (this.data.questions || []).map((q) => {
      const prepop = DEFAULT_QUESTION;
      const validators = [q.required ? Validators.required : null, q.regex ? Validators.pattern(q.regex) : null].filter((x) => x);
      this.form.addControl(q.key, new FormControl('', Validators.compose([...validators])));
      return { ...prepop, ...q };
    });
    this.isEmail = true;
    this.toggleEmailPhone('email');
    this.form.get('contact')?.valueChanges.subscribe((value: boolean) => {
      this.toggleContactMethod(value);
    });
    this.form.get('contactMethod')?.valueChanges.subscribe((value: string) => {
      this.toggleEmailPhone(value);
    });
    this.profile$ = this.userService.profile$;
  }

  classFor(question: Question<any>) {
    return question.optionClass?.(this.form.get(question.key).value);
  }

  addAuthenticatedComponents() {
    if (!this.auth.isAuthenticated() && !this.data.booking) {
      this._isAuth = false;
      this.form.addControl('name', new FormControl('', Validators.required));
      this.form.addControl('id_number', new FormControl(null));
      this.form.addControl('cellnr', new FormControl(null));
      this.form.addControl('email', new FormControl(null));
      this.form.controls.email.setValidators(Validators.compose([Validators.required, Validators.email]));
      this.form.controls.email.updateValueAndValidity();
    } else {
      this._isAuth = true;
    }
  }

  submit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
