import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RegisterGuard {
  constructor(private readonly auth: AuthService, private readonly router: Router, private readonly user: UserService) {}
  canActivate() {
    const profileVerified$ = this.user.profile$.pipe(
      filter((user) => !!user),
      map((user) => user.cellnrVerified),
    );
    return this.auth.authenticated$.pipe(
      switchMap((authenticated) => (authenticated ? profileVerified$ : of(false))),
      tap((authedAndVerified) => {
        if (authedAndVerified) {
          this.router.navigate(['/dashboard']);
        }
      }),
      map((authedAndVerified) => !authedAndVerified),
    );
  }
}
