import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Booking } from '@models/booking.model';
import { Doctor } from '@models/doctor.model';
import { AlertService } from '@services/alert.service';
// import { AuthService } from '@services/auth.service';
import { BookingService } from '@services/booking.service';
import { DateTime, Duration } from 'luxon';
import { catchError, of, switchMap } from 'rxjs';

@Component({
  selector: 'gc-booking-display-card',
  templateUrl: './booking-display-card.component.html',
  styleUrls: ['./booking-display-card.component.scss'],
})
export class BookingDisplayCardComponent {
  @Input() booking: Booking;
  @Input() showActions = true;
  @Output() bookingDeleted = new EventEmitter<boolean>();
  cancelBookingTime: DateTime;

  // private readonly authService: AuthService = inject(AuthService);
  constructor(
    private readonly router: Router,
    private readonly bookingService: BookingService,
    private readonly alertService: AlertService,
  ) {}

  get displayIcon() {
    return this.booking?.type?.icon;
  }

  timezone(doctor: Doctor) {
    return !doctor.timezoneJson?.timezone ? null : `${doctor.timezoneJson?.timezone} (${doctor.timezoneJson?.timezoneOffset})`;
  }

  hasDocuments(booking: Booking): boolean {
    return Object.values(booking?.whichDocuments).some((value) => value);
  }

  openTelemed(uuid: string) {
    this.router.navigate([`eth/${uuid}`]);
  }

  cancelBooking(uuid: string, bookDate: DateTime, cancellationHours: number) {
    const cancelBookingTime = bookDate.minus({ hours: cancellationHours ?? 0 });
    if (cancelBookingTime.diffNow('milliseconds').milliseconds <= 0) {
      this.alertService.messageDialog$(
        'Unable to cancel booking.',
        `Booking cancellation time has passed. <br>
        Please contact your practitioner for more details.`,
      );
    } else {
      const bookingCancel$ = this.bookingService.cancelBooking$(uuid).pipe(
        catchError((err: unknown) => {
          this.alertService.handleErrorDialog$(err, 'Unable to cancel booking.');
          return of(err);
        }),
      );
      this.alertService
        .confirmDialog$('Cancel booking?', { detail: 'Are you sure you wish to cancel your booking? This operation cannot be undone.' })
        .pipe(switchMap((res) => (res ? bookingCancel$ : of())))
        .subscribe((res) => {
          if (res === true) {
            this.cancelBookingTime = DateTime.now();
            this.bookingDeleted.emit(true);
            this.alertService.snackbar('Booking cancelled successfully.');
          }
        });
    }
  }

  isPastBooking(booking: Booking): boolean {
    return booking.date > DateTime.now();
  }

  showDuration(duration: Duration) {
    const minutes = duration.minutes;
    return `(${minutes} min) `;
  }
}
