import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { VERSION } from 'src/environments/version';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  version() {
    return VERSION.version.replace(/^v/, '');
  }

  date() {
    return DateTime.fromISO(VERSION.releaseDate);
  }
}
