import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigLocaleService } from '@services/configLocale.service';
import { take } from 'rxjs';
import { TRANSLATABLE_LANGUAGES } from 'src/app/constants';

@Component({
  selector: 'gc-language-selector',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss'],
})
export class TranslateComponent implements OnInit {
  selectedLocale: string;
  defaultLocale: string;
  languages = TRANSLATABLE_LANGUAGES;

  constructor(private readonly translate: TranslateService, private readonly configService: ConfigLocaleService) {}

  ngOnInit() {
    this.configService
      .getValueByKey$(['default_language'])
      .pipe(take(1))
      .subscribe((config) => {
        this.defaultLocale = config.default_language;
        if (localStorage.getItem('language') === 'undefined' || !localStorage.getItem('language')) {
          this.selectedLocale = this.defaultLocale;
        } else {
          this.selectedLocale = localStorage.getItem('language');
        }
        localStorage.setItem('language', this.selectedLocale);
        this.translate.use(this.selectedLocale);

        this.translate.currentLang = this.selectedLocale;
        this.translate.setTranslation(this.selectedLocale, require(`src/locale/${this.selectedLocale}.json`));
      });
  }

  changeLanguage(lang?: string) {
    if (!lang) {
      lang = this.defaultLocale;
    }
    this.selectedLocale = lang;
    localStorage.setItem('language', lang);
    this.translate.use(lang);

    this.translate.currentLang = lang;
    this.translate.setTranslation(lang, require(`src/locale/${lang}.json`));
    this.windowReload();
  }

  windowReload() {
    window.location.reload();
  }
}
