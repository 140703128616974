<div class="container">
  <div class="grid-container">
    <div class="logo-backdrop"></div>
    <div class="mobile-page-header">
      <button mat-button type="button" (click)="onBackClick()">
        <mat-icon svgIcon="arrow-left"></mat-icon>
      </button>
      <span>Reset your password</span>
    </div>
    <div class="forgot-password-container">
      <gc-login-splash class="login-splash-password"></gc-login-splash>
      <gc-reset-password></gc-reset-password>
    </div>
  </div>
</div>
