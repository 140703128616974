<div class="container">
  <div class="content">
    <gc-logged-out-header></gc-logged-out-header>
    <div class="logo-backdrop">
      <button class="back-btn" mat-button type="button" routerLink="/landing">
        <mat-icon svgIcon="keyboard-backspace"></mat-icon>
        Back
      </button>
    </div>
    <gc-register-first-step (stepComplete)="firstStepComplete()" *ngIf="!stepsComplete[0]; else secondStep"></gc-register-first-step>
    <ng-template #secondStep>
      <gc-register-second-step (stepComplete)="secondStepComplete()"></gc-register-second-step>
    </ng-template>
  </div>
</div>
