import { DateTime } from 'luxon';

export type QuestionnaireItemType =
  | 'dropdown'
  | 'radio'
  | 'checkboxes'
  | 'checked_dropdown'
  | 'slider'
  | 'integer'
  | 'decimal'
  | 'boolean'
  | 'text'
  | 'paragraph'
  | 'date'
  | 'time'
  | 'file'
  | 'image'
  | 'static_text'
  | 'divider'
  | 'signature'
  | 'drawable_image';

export type QuestionnaireOptionData = {
  option: string;
  value?: string;
  other_option?: boolean;
  disabled?: boolean;
};

export type ImageData = {
  type: 'image';
  name?: string;
  src: string;
  original_src?: string;
};

export type PdfData = {
  type: 'file';
  report_description: string;
  report_name: string;
  report_pdf: string;
};

export type QuestionnaireItemData = {
  id: number;
  type: QuestionnaireItemType;
  name: string;
  required: boolean;
  min_value?: number;
  max_value?: number;
  min_label?: string;
  max_label?: string;
  description?: string;
  image_list?: ImageData[];
  pdf_list?: PdfData[];
  value?: any;
  options?: QuestionnaireOptionData[];
  disabled?: boolean;
};

export type QuestionnaireData = {
  uuid: string;
  booking_uuid?: string;
  operation_uuid?: string;
  doctor: string;
  title: string;
  description?: string;
  timestamp: string;
  complete: boolean;
  items: QuestionnaireItemData[];
};

// T: Form control value type
// U: Serialised value type
export abstract class QuestionnaireItem<T = any, U = T> {
  readonly id: number;
  readonly type: QuestionnaireItemType;
  readonly name: string;
  readonly required: boolean;
  readonly description?: string;
  readonly _value: U;
  disabled?: boolean;

  constructor(data: QuestionnaireItemData) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.required = data.required;
    this.description = data.description;
    this._value = data.value;
  }

  abstract get value(): T;

  get label(): string {
    return this.name;
  }

  get key(): string {
    return [
      ...this.name
        .trim()
        .split(/\s+/)
        .map((part) => part.toLowerCase()),
      this.id,
    ].join('-');
  }
  abstract transformValueForSubmission(value: T): U;
}

export class TextQuestionnaireItem extends QuestionnaireItem<string> {
  get value() {
    return this._value;
  }

  transformValueForSubmission(value: string) {
    return value;
  }
}

export abstract class SelectQuestionnaireItem<T = QuestionnaireOptionData, U = string> extends QuestionnaireItem<T, U> {
  readonly options: QuestionnaireOptionData[];
  constructor(data: QuestionnaireItemData) {
    super(data);
    this.options = data.options;
  }

  get otherOption(): QuestionnaireOptionData {
    return this.options.find((o) => o.other_option);
  }

  abstract get otherValue(): string;

  protected findOption(opt: string): QuestionnaireOptionData {
    return opt ? this.options.find((o) => o.option === opt) ?? this.otherOption : null;
  }
}

export class SingleSelectQuestionnaireItem extends SelectQuestionnaireItem<QuestionnaireOptionData> {
  get value(): any {
    return this.findOption(this._value);
  }

  get otherValue(): string {
    return this.findOption(this._value) === this.otherOption ? this._value : null;
  }

  transformValueForSubmission(value: QuestionnaireOptionData) {
    return value.other_option ? value.value : value.option;
  }
}

export class MultiSelectQuestionnaireItem extends SelectQuestionnaireItem<QuestionnaireOptionData[], string[]> {
  readonly _value: string[];
  get value() {
    return (this._value as string[])?.map((v) => this.findOption(v)).filter((v) => !!v) ?? [];
  }

  get otherValue(): string {
    return this._value?.find((v) => this.findOption(v) === this.otherOption);
  }

  transformValueForSubmission(value: QuestionnaireOptionData[]): string[] {
    return value.map((v) => (v.other_option ? null : v.option));
  }
}

export class IntegerQuestionnaireItem extends QuestionnaireItem<number | string, number> {
  readonly minValue: number;
  readonly maxValue: number;
  constructor(data: QuestionnaireItemData) {
    super(data);
    this.minValue = data.min_value;
    this.maxValue = data.max_value;
  }

  get value(): number {
    return this._value;
  }

  transformValueForSubmission(value: string | number): number {
    return typeof value === 'string' ? parseInt(value, 10) : value;
  }
}

export class DecimalQuestionnaireItem extends IntegerQuestionnaireItem {
  get value(): number {
    return this._value;
  }

  transformValueForSubmission(value: string | number): number {
    return typeof value === 'string' ? parseFloat(value) : value;
  }
}

export class SliderQuestionnaireItem extends IntegerQuestionnaireItem {
  readonly minLabel: string;
  readonly maxLabel: string;

  constructor(data: QuestionnaireItemData) {
    super(data);
    this.minLabel = data.min_label;
    this.maxLabel = data.max_label;
  }
}

export class BooleanQuestionnaireItem extends QuestionnaireItem<boolean> {
  get value(): boolean {
    return this._value;
  }

  transformValueForSubmission(value: boolean): boolean {
    return value;
  }
}

export class DateTimeQuestionnaireItem extends QuestionnaireItem<DateTime | string, string> {
  get value(): string {
    return this._value;
  }

  transformValueForSubmission(value: DateTime | string) {
    if (typeof value === 'string') {
      value = DateTime.fromISO(value);
    }
    return value.isValid ? value.toFormat(this.type === 'date' ? 'yyyy-MM-dd' : 'HH:mm') : null;
  }
}

export class FileQuestionnaireItem extends QuestionnaireItem<PdfData[]> {
  readonly pdfList: PdfData[];

  constructor(data: QuestionnaireItemData) {
    super(data);
    this.pdfList = data.pdf_list || data.value || [];
  }

  get value(): PdfData[] {
    return this.pdfList;
  }

  transformValueForSubmission(value: PdfData[]) {
    return value;
  }
}

export class ImageQuestionnaireItem extends QuestionnaireItem<ImageData[]> {
  readonly imageList: ImageData[];

  constructor(data: QuestionnaireItemData) {
    super(data);
    this.imageList = data.image_list || [];
  }

  get value(): ImageData[] {
    return this.imageList;
  }

  transformValueForSubmission(value: ImageData[]) {
    return value;
  }
}

export class DrawableImageQuestionnaireItem extends QuestionnaireItem<string> {
  constructor(data: QuestionnaireItemData) {
    super({ ...data, value: data.image_list?.[0]?.src || data.value });
  }

  transformValueForSubmission(value: string) {
    return value;
  }

  get value() {
    return this._value;
  }
}

export class StaticQuestionnaireItem extends QuestionnaireItem<void> {
  constructor(data: QuestionnaireItemData) {
    super({ ...data, required: false });
  }

  get value() {
    return;
  }

  transformValueForSubmission() {
    return;
  }
}

export class SignatureQuestionnaireItem extends QuestionnaireItem<string> {
  constructor(data: QuestionnaireItemData) {
    super({ ...data });
  }
  transformValueForSubmission(value: string) {
    return value;
  }

  get value() {
    return this._value;
  }
}

export class QuestionnaireItemFactory {
  public static lookupDict = {
    radio: SingleSelectQuestionnaireItem,
    dropdown: SingleSelectQuestionnaireItem,
    checkboxes: MultiSelectQuestionnaireItem,
    checked_dropdown: MultiSelectQuestionnaireItem,
    text: TextQuestionnaireItem,
    paragraph: TextQuestionnaireItem,
    slider: SliderQuestionnaireItem,
    integer: IntegerQuestionnaireItem,
    decimal: DecimalQuestionnaireItem,
    date: DateTimeQuestionnaireItem,
    time: DateTimeQuestionnaireItem,
    image: ImageQuestionnaireItem,
    file: FileQuestionnaireItem,
    divider: StaticQuestionnaireItem,
    static_text: StaticQuestionnaireItem,
    boolean: BooleanQuestionnaireItem,
    signature: SignatureQuestionnaireItem,
    drawable_image: DrawableImageQuestionnaireItem,
  };
  public static create(data: QuestionnaireItemData): QuestionnaireItem {
    return new QuestionnaireItemFactory.lookupDict[data.type](data);
  }
}

export class Questionnaire {
  uuid: string;
  bookingUuid?: string;
  operationUuid?: string;
  title: string;
  description?: string;
  complete: boolean;
  doctor: string;
  timestamp: DateTime;
  items: QuestionnaireItem[];

  constructor(data: QuestionnaireData) {
    this.uuid = data.uuid;
    this.bookingUuid = data.booking_uuid;
    this.operationUuid = data.operation_uuid;
    this.title = data.title;
    this.description = data.description;
    this.complete = data.complete;
    this.doctor = data.doctor;
    this.timestamp = DateTime.fromISO(data.timestamp);
    this.items = data.items.map((i) => QuestionnaireItemFactory.create(i));
  }
}
