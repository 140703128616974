import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'gc-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent {
  showPassword = false;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private readonly auth: AuthService,
    private readonly alert: AlertService,
    private readonly fb: FormBuilder,
    private readonly socialService: SocialAuthService,
  ) {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.socialService.authState.subscribe((user) => {
      this.auth.postGoogleSignInCredentials(user.idToken);
      this.signInWithGoogle();
    });
  }

  signInWithGoogle() {
    this.auth.authenticated$.pipe(distinctUntilChanged()).subscribe((authenticated) => {
      if (authenticated) {
        this.dialogRef.close(true);
      }
    });
  }

  submit() {
    if (this.form.valid) {
      this.auth
        .login$(this.form.value.username, this.form.value.password)
        .pipe(
          distinctUntilChanged(),
          switchMap((authenticated) => {
            if (authenticated) {
              this.alert.snackbar('Successfully logged in', 'Close');
              this.dialogRef.close(true);
              return of(authenticated);
            } else {
              this.alert.snackbar('Invalid username or password', 'Close');
              return of(authenticated);
            }
          }),
          catchError((err: unknown) => this.alert.handleErrorDialog$(err, 'Failed to log in')),
        )
        .subscribe();
    } else {
      this.form.markAllAsTouched();
    }
  }
}
