import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { passwordValidator } from '@validators/password.validator';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'gc-complete-reset-password',
  templateUrl: './complete-reset-password.component.html',
  styleUrls: ['./complete-reset-password.component.scss'],
})
export class CompleteResetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  showPassword = false;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), passwordValidator]],
      code: [''],
    });
    this.route.queryParamMap
      .pipe(
        take(1),
        filter((params) => !!params.get('code')),
      )
      .subscribe((params) => {
        this.passwordForm.patchValue({ code: params.get('code') });
      });
  }

  passwordErrors() {
    switch (true) {
      case this.passwordForm.get('password').hasError('required'):
        return 'Password is required';
      case this.passwordForm.get('password').hasError('minlength'):
        return 'Password must be at least 8 characters long';
      case this.passwordForm.get('password').hasError('passwordValidator'):
        const errors = this.passwordForm.get('password').errors.passwordValidator;
        if (errors?.number) {
          return 'Password must contain at least 1 number';
        } else if (errors?.letter) {
          return 'Password must contain at least 1 letter';
        } else if (errors?.password) {
          return "Password cannot contain common words such as 'password'";
        }
      default:
        return null;
    }
  }

  submit() {
    const { code, password } = this.passwordForm.value;
    this.auth
      .verifyPasswordReset(code, password)
      .pipe(filter((result) => !!result?.success))
      .subscribe((res) => {
        this.alertService.snackbar(res?.message);
        this.router.navigate(['/login']);
      });
  }
}
