import { DateTime } from 'luxon';
import { Notification, NotificationData } from './notification.model';

export class DemographicNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);
    this.actionName = 'View Access';
    this.actionRequired = !this.readDate;
    const requestDate = DateTime.fromISO(data?.demographicRequest?.requested_timestamp);
    this.description = [
      `${data?.demographicRequest?.doctor_name ?? 'A practitioner'} has requested your demographic information`,
      `on ${requestDate.toFormat('HH:mm')}`,
      `on ${requestDate.toFormat('cccc, dd LLLL')}`,
    ].join(' ');
  }
}
