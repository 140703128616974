<form [formGroup]="registerForm" class="register-form">
  <gc-login-splash class="login-splash-register"></gc-login-splash>
  <mat-card class="complete-details">
    <mat-card-header class="details-card-header">
      <span class="step-span">Step 1 of 2:</span>
      <span>Complete your details</span>
    </mat-card-header>
    <mat-card-content class="form-card-content">
      <gc-input-field
        formControlName="name"
        label="First name(s) *"
        statusIcon
        autocomplete="given-name"
        name="given-name"
        [error]="false"
      ></gc-input-field>
      <gc-input-field
        formControlName="surname"
        label="Surname *"
        statusIcon
        autocomplete="family-name"
        name="family-name"
        [error]="false"
      ></gc-input-field>
      <gc-input-field
        formControlName="cellnr"
        type="tel"
        autocomplete="tel"
        name="mobile"
        statusIcon
        label="Mobile Number *"
        [error]="false"
        infoMessage="We need to make sure that your mobile number works in order to send you your account activation PIN, as well as other important information and reminders."
        infoTitle="Why do you need my mobile number?"
      ></gc-input-field>
      <gc-input-field
        formControlName="email"
        type="email"
        autocomplete="email"
        name="email"
        statusIcon
        label="Email Address *"
        [error]="false"
      ></gc-input-field>
      <gc-input-field
        formControlName="idnumber"
        type="tel"
        name="idnumber"
        statusIcon
        label="{{ 'ID number ' + (idRequired ? '*' : '') }}"
        [error]="false"
        infoMessage="We make sure that each person who signs up to myGC has only one account. This way your medical records stay correct and secure."
        infoTitle="Why do you need my ID number?"
        class="idnumber-field"
        (change)="populateDobAndGender()"
        *ngIf="!idHidden"
      ></gc-input-field>
      <mat-form-field>
        <mat-label>Date of Birth</mat-label>
        <input matInput type="text" formControlName="dob" autocomplete="bday" [matDatepicker]="dobPicker" [max]="maxDate" />
        <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
        <mat-datepicker #dobPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Gender</mat-label>
        <mat-select formControlName="gender" autocomplete="sex">
          <mat-option *ngFor="let gender of genderOptions" [value]="gender">
            {{ gender }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="multiline-error">
        <mat-label>Password</mat-label>
        <input
          matInput
          formControlName="password"
          [type]="showPassword ? 'text' : 'password'"
          name="password"
          autocomplete="new-password"
        />
        <button
          mat-icon-button
          matSuffix
          type="button"
          *ngIf="registerForm.get('password').value"
          (click)="showPassword = !showPassword"
          tabindex="-1"
        >
          <mat-icon [svgIcon]="showPassword ? 'eye-off' : 'eye'"></mat-icon>
        </button>
        <mat-error>
          {{ passwordErrors() }}
        </mat-error>
      </mat-form-field>
      <div class="footer-mobile">
        <button color="accent" mat-raised-button type="button" (click)="feedback()">
          <mat-icon svgIcon="comment-quote"></mat-icon>
          <span>Feedback</span>
        </button>
        <button mat-raised-button color="primary" type="button" (click)="register()">
          <span>Next step</span>
        </button>
      </div>
      <div class="divider"><span>OR</span></div>
      <div class="google-btn-div">
        <asl-google-signin-button
          type="standard"
          theme="filled_blue"
          shape="pill"
          size="medium"
          text="signup_with"
        ></asl-google-signin-button>
      </div>
      <div class="footer-desktop">
        <button color="accent" mat-raised-button type="button" (click)="feedback()">
          <mat-icon svgIcon="comment-quote"></mat-icon>
          <span>Feedback</span>
        </button>
        <button color="primary" mat-raised-button type="button" (click)="register()">
          <span>Next step</span>
          <mat-icon svgIcon="arrow-right"></mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
