<div class="content" *ngIf="minimal; else full">
  <button
    mat-icon-button
    *ngFor="let type of types"
    class="filter-button fit-icon"
    [class.selected]="type.selected"
    (click)="onToggleType(type)"
  >
    <mat-icon [svgIcon]="type.icon" class="icon"></mat-icon>
  </button>
</div>
<ng-template #full>
  <div class="content column">
    <div *ngFor="let type of types" class="type" [class.selected]="type.selected" (click)="onToggleType(type)">
      <div class="type-group">
        <mat-icon [svgIcon]="type.icon" class="icon"></mat-icon>
        <ng-container>
          <span class="title">{{ type.title }}</span>
        </ng-container>
      </div>
      <mat-icon *ngIf="type.selected" svgIcon="close" class="close-icon"></mat-icon>
    </div>
  </div>
</ng-template>
