<div #container class="input-dialog">
  <h1 mat-dialog-title class="title-group">
    <mat-icon svgIcon="lock"></mat-icon>
    <span class="title">Login</span>
  </h1>
  <div mat-dialog-content>
    <form [formGroup]="form" (submit)="submit()" class="form">
      <mat-form-field class="question-container">
        <mat-label>Email/Mobile No./ID No.</mat-label>
        <input matInput formControlName="username" type="text" />
      </mat-form-field>
      <mat-form-field class="question-container">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          autocomplete="current-password"
          formControlName="password"
          name="password"
          autocomplete="current-password"
          gcFormField
        />
        <button mat-icon-button matSuffix type="button" (click)="showPassword = !showPassword">
          <mat-icon [svgIcon]="showPassword ? 'eye-off' : 'eye'"></mat-icon>
        </button>
        <mat-error>Password is required</mat-error>
      </mat-form-field>
      <asl-google-signin-button
        type="standard"
        theme="filled_blue"
        shape="pill"
        size="medium"
        text="signin_with"
      ></asl-google-signin-button>
    </form>
    <div class="register">
      <span>
        Don't have an account?
        <a mat-dialog-close routerLink="/register">Sign up</a>
      </span>
    </div>
    <div mat-dialog-actions class="actions">
      <button mat-button mat-dialog-close class="cancel">Cancel</button>
      <button mat-button type="submit" (click)="submit()" [disabled]="!form.valid" color="primary">Login</button>
    </div>
  </div>
</div>
